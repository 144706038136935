<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Loading data...</p>
        </div>
      </div>

      <!-- Header -->
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <h1 class="text-2xl font-semibold">Validasi SOP</h1>
          <div class="text-sm text-gray-500">
            Total: {{ pagination?.total_items || 0 }} data
          </div>
        </div>

        <!-- Enhanced Filters -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <!-- Search -->
          <div class="relative">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Cari nomor SO, nama pegawai, kode SOP..."
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            />
            <div class="absolute left-3 top-2.5">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
            </div>
          </div>

          <!-- Role Filter -->
          <div class="flex rounded-lg border overflow-hidden">
            <button
              @click="selectedRole = ''"
              class="flex-1 px-4 py-2 text-sm font-medium"
              :class="selectedRole === '' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'"
            >
              Semua
            </button>
            <button
              @click="selectedRole = true"
              class="flex-1 px-4 py-2 text-sm font-medium border-l"
              :class="selectedRole === true ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'"
            >
              Service Advisor
            </button>
            <button
              @click="selectedRole = false"
              class="flex-1 px-4 py-2 text-sm font-medium border-l"
              :class="selectedRole === false ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50'"
            >
              Mekanik
            </button>
          </div>

          <!-- Month Filter -->
          <div class="relative">
            <input
              v-model="selectedMonth"
              type="month"
              class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @change="handleFilterChange"
            />
          </div>
        </div>

        <!-- Enhanced Table -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="text-left p-4 font-medium">No. SO & Kendaraan</th>
                <th class="text-left p-4 font-medium">SOP & Petugas</th>
                <th class="text-left p-4 font-medium">Waktu</th>
                <th class="text-left p-4 font-medium">Status</th>
                <th class="text-center p-4 font-medium">Aksi</th>
              </tr>
            </thead>
            <tbody class="divide-y">
              <tr v-for="sampling in samplings" :key="sampling.id" class="hover:bg-gray-50">
                <!-- SO & Kendaraan Info -->
                <td class="p-4">
                  <div class="space-y-2">
                    <div class="font-medium text-blue-600">{{ sampling.sale_order.name }}</div>
                    <div class="flex items-center gap-2">
                      <span class="px-2 py-1 bg-gray-100 rounded font-medium text-sm">
                        {{ sampling.sale_order.car_info.plate }}
                      </span>
                      <span class="text-sm text-gray-600">
                        {{ sampling.sale_order.car_info.brand }} {{ sampling.sale_order.car_info.type }}
                      </span>
                    </div>
                  </div>
                </td>

                <!-- SOP & Employee Info -->
                <td class="p-4">
                  <div class="space-y-2">
                    <!-- SOP Info -->
                    <div class="flex items-center gap-2">
                      <span class="font-medium">{{ sampling.sop.code }}</span>
                      <span 
                        class="px-2 py-0.5 rounded-full text-xs font-medium"
                        :class="sampling.sop.is_sa ? 'bg-purple-100 text-purple-800' : 'bg-green-100 text-green-800'"
                      >
                        {{ sampling.sop.is_sa ? 'SA' : 'MK' }}
                      </span>
                    </div>
                    <div class="text-sm text-gray-600">{{ sampling.sop.name }}</div>
                    
                    <!-- Employee - dengan defensive checking -->
                    <div class="flex items-center gap-2 text-sm">
                      <UserIcon class="w-4 h-4 text-gray-400" />
                      <span class="font-medium">
                        {{ getEmployeeName(sampling) }}
                      </span>
                    </div>
                  </div>
                </td>


                <!-- Timestamps -->
                <td class="p-4">
                  <div class="space-y-1">
                    <div class="flex items-center gap-2 text-sm">
                      <ClockIcon class="w-4 h-4 text-gray-400" />
                      <span>{{ formatDateTime(sampling.timestamps.created) }}</span>
                    </div>
                    <div v-if="sampling.controller" class="flex items-center gap-2 text-sm text-gray-500">
                      <UserCircleIcon class="w-4 h-4" />
                      <span>{{ sampling.controller.name }}</span>
                    </div>
                  </div>
                </td>

                <!-- Status & Notes -->
                <td class="p-4">
                  <div class="space-y-2">
                    <!-- Status Badge -->
                    <div class="flex items-center gap-2">
                      <span 
                        class="px-2.5 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1"
                        :class="{
                          'bg-gray-100 text-gray-800': sampling.state === 'draft',
                          'bg-yellow-100 text-yellow-800': sampling.state === 'in_progress',
                          'bg-green-100 text-green-800': sampling.state === 'done' && sampling.result === 'pass',
                          'bg-red-100 text-red-800': sampling.state === 'done' && sampling.result === 'fail'
                        }"
                      >
                        <component 
                          :is="getStatusIcon(sampling)" 
                          class="w-3.5 h-3.5" 
                        />
                        {{ getStatusLabel(sampling) }}
                      </span>
                    </div>
                    
                  </div>
                </td>

                <!-- Actions -->
                <td class="p-4 text-center">
                  <Menu as="div" class="relative inline-block text-left">
                    <!-- Enhanced Button -->
                    <MenuButton 
                      class="min-w-[120px] px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-lg hover:bg-blue-600 active:bg-blue-700 transition-all shadow-sm inline-flex items-center justify-center gap-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <span class="inline-flex items-center gap-2">
                        <template v-if="sampling.state === 'draft'">
                          <ClipboardDocumentCheckIcon class="w-4 h-4" />
                          <span>Validasi</span>
                        </template>
                        <template v-else>
                          <EyeIcon class="w-4 h-4" />
                          <span>Lihat</span>
                        </template>
                      </span>
                      <ChevronDownIcon class="w-4 h-4 opacity-70" />
                    </MenuButton>

                    <transition
                      enter-active-class="transition duration-100 ease-out"
                      enter-from-class="transform scale-95 opacity-0"
                      enter-to-class="transform scale-100 opacity-100"
                      leave-active-class="transition duration-75 ease-in"
                      leave-from-class="transform scale-100 opacity-100"
                      leave-to-class="transform scale-95 opacity-0"
                    >
                      <MenuItems 
                        class="absolute right-0 mt-2 min-w-[160px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 origin-top-right divide-y divide-gray-100 border border-gray-100"
                      >
                        <!-- Primary Action -->
                        <div class="py-1">
                          <MenuItem v-slot="{ active }">
                            <button
                              @click="openValidationDialog(sampling)"
                              :class="[
                                'w-full px-4 py-2 text-left text-sm group flex items-center gap-2',
                                active ? 'bg-gray-50 text-blue-600' : 'text-gray-700 hover:text-gray-900',
                                'transition-colors duration-75'
                              ]"
                            >
                              <span 
                                :class="[
                                  'rounded-full p-1',
                                  active ? 'bg-blue-50' : 'bg-gray-50'
                                ]"
                              >
                                <ClipboardDocumentCheckIcon v-if="sampling.state === 'draft'" class="w-4 h-4" />
                                <EyeIcon v-else class="w-4 h-4" />
                              </span>
                              {{ sampling.state === 'draft' ? 'Validasi' : 'Lihat Detail' }}
                            </button>
                          </MenuItem>
                        </div>

                        <!-- Secondary Actions -->
                        <div v-if="sampling.state === 'done'" class="py-1">
                          <MenuItem v-slot="{ active }">
                            <button
                              @click="openValidationDialog(sampling, true)"
                              :class="[
                                'w-full px-4 py-2 text-left text-sm group flex items-center gap-2',
                                active ? 'bg-gray-50 text-blue-600' : 'text-gray-700 hover:text-gray-900',
                                'transition-colors duration-75'
                              ]"
                            >
                              <span 
                                :class="[
                                  'rounded-full p-1',
                                  active ? 'bg-blue-50' : 'bg-gray-50'
                                ]"
                              >
                                <PencilSquareIcon class="w-4 h-4" />
                              </span>
                              Edit Hasil
                            </button>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Enhanced Pagination -->
        <div class="flex justify-between items-center">
          <div class="text-sm text-gray-600">
            Menampilkan {{ startNumber }} - {{ endNumber }} dari {{ pagination?.total_items || 0 }} data
          </div>
          
          <div class="flex items-center gap-4">
            <select
              v-model="itemsPerPage"
              @change="handlePerPageChange"
              class="border rounded-lg px-3 py-1.5 text-sm bg-white"
            >
              <option :value="10">10 per halaman</option>
              <option :value="20">20 per halaman</option>
              <option :value="50">50 per halaman</option>
              <option :value="100">100 per halaman</option>
            </select>

            <div class="flex gap-2">
              <button
                @click="handlePageChange(pagination.current_page - 1)"
                :disabled="pagination?.current_page === 1"
                class="px-3 py-1.5 border rounded-lg text-sm hover:bg-gray-50 disabled:opacity-50"
              >
                Sebelumnya
              </button>
              
              <div v-for="page in pageNumbers" :key="page" class="flex items-center">
                <button
                  v-if="page !== '...'"
                  @click="handlePageChange(page)"
                  :class="[
                    'px-3 py-1.5 border rounded-lg text-sm',
                    pagination?.current_page === page
                      ? 'bg-blue-50 border-blue-500 text-blue-600'
                      : 'hover:bg-gray-50'
                  ]"
                >
                  {{ page }}
                </button>
                <span v-else class="px-2">...</span>
              </div>

              <button
                @click="handlePageChange(pagination.current_page + 1)"
                :disabled="!pagination?.has_next"
                class="px-3 py-1.5 border rounded-lg text-sm hover:bg-gray-50 disabled:opacity-50"
              >
                Selanjutnya
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Validation Dialog -->
    <SamplingValidationDialog
      :is-open="showValidationDialog"
      :sampling="selectedSampling"
      v-model:is-edit="isEditMode"
      :on-close="closeValidationDialog"
      :on-validated="handleValidated"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { toast } from 'vue-sonner'
import debounce from 'lodash/debounce'
import apiClient from '../../config/api'
import SamplingValidationDialog from './Validate.vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon, MagnifyingGlassIcon, UserIcon, UserCircleIcon, CheckCircleIcon, XCircleIcon, ClockIcon, 
  ClipboardDocumentCheckIcon,
  EyeIcon,
  PencilSquareIcon  } from '@heroicons/vue/24/outline'

// State
const loading = ref(false)
const samplings = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const selectedRole = ref('')
const selectedMonth = ref(new Date().toISOString().slice(0, 7))
const isEditMode = ref(false)
// State
const itemsPerPage = ref(20)

// Computed
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

// Update fetchData untuk memastikan data employee terstruktur dengan benar
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        month: selectedMonth.value,
        // is_sa: selectedRole.value === '' ? null : selectedRole.value
        role: selectedRole.value === '' ? null : selectedRole.value
      }
    })

    if (response?.data?.result?.status === 'success') {
      // Pastikan employee data terstruktur dengan benar
      const normalizedSamplings = response.data.result.data.rows.map(sampling => ({
        ...sampling,
        employee_info: sampling.employee_info || sampling.employee || {
          service_advisor: [],
          mechanic: [],
          valet: [],
          part_support: []
        }
      }))
      
      samplings.value = normalizedSamplings
      pagination.value = response.data.result.data.pagination
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Failed to load data: ' + error.message)
  } finally {
    loading.value = false
  }
}


const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const totalPages = Math.ceil(pagination.value.total_items / itemsPerPage.value)
  const current = pagination.value.current_page
  const delta = 2 // Jumlah halaman yang ditampilkan sebelum dan sesudah halaman saat ini
  const pages = []
  
  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 || // Halaman pertama
      i === totalPages || // Halaman terakhir
      (i >= current - delta && i <= current + delta) // Halaman sekitar current
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

const handlePerPageChange = () => {
  // Reset ke halaman pertama ketika mengubah jumlah item per halaman
  fetchData({ 
    page: 1,
    limit: itemsPerPage.value 
  })
}

const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = () => {
  fetchData({ page: 1 })
}

const handlePageChange = (page) => {
  fetchData({ page })
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  // Tambah 7 jam untuk WIB
  date.setHours(date.getHours() + 7)
  
  return date.toLocaleDateString('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}


// / Format date utility
const formatDateTime = (timestamp) => {
  if (!timestamp) return '-'
  const date = new Date(timestamp)
  date.setHours(date.getHours() + 7)
  
  return date.toLocaleString('id-ID', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Get status icon
const getStatusIcon = (sampling) => {
  if (sampling.state === 'draft') return ClockIcon
  if (sampling.state === 'in_progress') return ClockIcon
  return sampling.result === 'pass' ? CheckCircleIcon : XCircleIcon
}

// Status label helper
const getStatusLabel = (sampling) => {
  if (sampling.state === 'draft') return 'Draft'
  if (sampling.state === 'in_progress') return 'In Progress'
  return sampling.result === 'pass' ? 'Lulus' : 'Tidak Lulus'
}

const showValidationDialog = ref(false)
const selectedSampling = ref(null)

// Update close handler
const closeValidationDialog = () => {
  showValidationDialog.value = false
  isEditMode.value = false // Reset edit mode when closing
}

// Update open handler
const openValidationDialog = (sampling, isEdit = false) => {
  selectedSampling.value = sampling
  isEditMode.value = isEdit
  showValidationDialog.value = true
}

// Tambahkan helper function untuk mendapatkan nama employee
const getEmployeeName = (sampling) => {
  // Cek apakah menggunakan format employee_info atau employee
  const employeeData = sampling.employee_info || sampling.employee
  
  if (!employeeData) return '-'
  
  if (sampling.sop.role === 'mechanic' && employeeData.mechanic?.length > 0) {
    return employeeData.mechanic[0].name
  }
  
  if (sampling.sop.role === 'service_advisor' && employeeData.service_advisor?.length > 0) {
    return employeeData.service_advisor[0].name
  }

  if (sampling.sop.role === 'valet' && employeeData.valet?.length > 0) {
    return employeeData.valet[0].name
  }

  if (sampling.sop.role === 'part_support' && employeeData.part_support?.length > 0) {
    return employeeData.part_support[0].name
  }

  return '-'
}

// Update computed properties untuk handle is_sa
const getEmployeeRole = (sampling) => {
  if (!sampling?.sop?.role) return ''
  
  switch (sampling.sop.role) {
    case 'mechanic':
      return 'MK'
    case 'service_advisor':
      return 'SA'
    case 'valet':
      return 'VP'
    case 'part_support':
      return 'PS'
    default:
      return ''
  }
}


const handleValidated = () => {
  fetchData()
}

onMounted(() => {
  fetchData()
})
</script>