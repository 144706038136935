// Template section dengan defensive checking
<template>
  <div v-if="roleData" class="bg-white rounded-xl border p-6 shadow-sm">
    <!-- Header -->
    <div class="flex items-center justify-between mb-6">
      <div>
        <h3 class="text-lg font-medium">{{ getRoleLabel }}</h3>
        <p class="text-gray-500 mt-1">{{ roleData.total || 0 }} total samplings</p>
      </div>
      <div :class="[roleColors[roleKey]?.medium || 'bg-gray-100', roleColors[roleKey]?.text || 'text-gray-600', 'rounded-full p-3']">
        <component :is="getRoleIcon" class="w-6 h-6" />
      </div>
    </div>

    <!-- Role Stats -->
    <div class="grid grid-cols-3 gap-4 mb-6">
      <div :class="[roleColors[roleKey]?.light || 'bg-gray-50', 'rounded-xl p-4']">
        <p :class="[roleColors[roleKey]?.text || 'text-gray-600', 'text-sm']">Total Samplings</p>
        <p class="text-2xl font-bold mt-1">{{ roleData.total || 0 }}</p>
      </div>
      <div class="bg-green-50 rounded-xl p-4">
        <p class="text-green-600 text-sm">Pass Rate</p>
        <p class="text-2xl font-bold mt-1">{{ formatPercentage(roleData.pass_rate) }}</p>
      </div>
      <div class="bg-red-50 rounded-xl p-4">
        <p class="text-red-600 text-sm">Fail Rate</p>
        <p class="text-2xl font-bold mt-1">{{ formatPercentage(roleData.fail_rate) }}</p>
      </div>
    </div>

    <!-- Staff Performance Cards -->
    <div class="space-y-4">
      <template v-if="visibleStaff.length">
        <div v-for="staff in visibleStaff" :key="staff.id" 
             :class="[roleColors[roleKey]?.light || 'bg-gray-50', 'rounded-xl p-4 border transition-all duration-200 hover:shadow-md', roleColors[roleKey]?.border || 'border-gray-200']">
          <div class="flex justify-between items-start mb-3">
            <div>
              <h4 class="font-medium">{{ staff.name }}</h4>
              <span class="text-sm text-gray-500">Rank #{{ staff.rank }}</span>
            </div>
            <div class="flex items-center gap-2">
              <div :class="[roleColors[roleKey]?.medium || 'bg-gray-100', roleColors[roleKey]?.text || 'text-gray-600', 'text-xs font-medium px-2.5 py-0.5 rounded-full']">
                {{ staff.total }} samplings
              </div>
              <div v-if="staff.rank <= 3" 
                   :class="getTrophyClass(staff.rank)"
                   class="w-6 h-6 flex items-center justify-center rounded-full">
                <TrophyIcon class="w-4 h-4" />
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-green-50 rounded-lg p-2">
              <div class="flex items-center gap-1 text-green-600 text-sm">
                <CheckCircleIcon class="w-4 h-4" />
                <span>Pass Rate</span>
              </div>
              <p class="text-lg font-semibold text-green-700">{{ formatPercentage(staff.pass_rate) }}</p>
              <p class="text-xs text-green-500">{{ staff.pass }} passed</p>
            </div>
            <div class="bg-red-50 rounded-lg p-2">
              <div class="flex items-center gap-1 text-red-600 text-sm">
                <XCircleIcon class="w-4 h-4" />
                <span>Fail Rate</span>
              </div>
              <p class="text-lg font-semibold text-red-700">{{ formatPercentage(staff.fail_rate) }}</p>
              <p class="text-xs text-red-500">{{ staff.fail }} failed</p>
            </div>
          </div>
        </div>
      </template>

      <!-- Load More Button -->
      <div v-if="hasMoreStaff" class="text-center pt-4">
        <button 
          @click="toggleShowMore"
          class="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors"
        >
          <span>{{ showMore ? 'Show Less' : `Show ${remainingStaffCount} More` }}</span>
          <component :is="showMore ? ChevronUpIcon : ChevronDownIcon" class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { 
  ChevronDownIcon, 
  ChevronUpIcon,
  TrophyIcon,
  CheckCircleIcon,
  XCircleIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
  TruckIcon,
  CubeIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
  roleKey: {
    type: String,
    required: true
  },
  roleData: {
    type: Object,
    required: true
  }
})

const showMore = ref(false)

// Computed properties dengan defensive checking
const visibleStaff = computed(() => {
  if (!props.roleData?.details) return []
  return showMore.value ? props.roleData.details : props.roleData.details.slice(0, 3)
})

const hasMoreStaff = computed(() => {
  return props.roleData?.details?.length > 3
})

const remainingStaffCount = computed(() => {
  if (!props.roleData?.details) return 0
  return props.roleData.details.length - 3
})

const getRoleLabel = computed(() => {
  const labelMap = {
    service_advisor: 'Service Advisor',
    mechanic: 'Mechanic',
    valet: 'Valet Parking',
    part_support: 'Part Support'
  }
  return labelMap[props.roleKey] || props.roleKey
})

const getRoleIcon = computed(() => {
  const iconMap = {
    service_advisor: UserCircleIcon,
    mechanic: WrenchScrewdriverIcon,
    valet: TruckIcon,
    part_support: CubeIcon
  }
  return iconMap[props.roleKey] || UserCircleIcon
})

// Helper functions
const formatPercentage = (value) => {
  if (!value && value !== 0) return '0.0%'
  return value.toFixed(1) + '%'
}

const toggleShowMore = () => {
  showMore.value = !showMore.value
}

const getTrophyClass = (rank) => {
  const baseClasses = 'bg-opacity-20'
  switch (rank) {
    case 1: return `bg-yellow-500 text-yellow-600 ${baseClasses}`
    case 2: return `bg-gray-400 text-gray-600 ${baseClasses}`
    case 3: return `bg-amber-600 text-amber-700 ${baseClasses}`
    default: return ''
  }
}

// Role colors dengan optional chaining
const roleColors = {
  service_advisor: {
    light: 'bg-purple-50',
    medium: 'bg-purple-100',
    text: 'text-purple-600',
    border: 'border-purple-200'
  },
  mechanic: {
    light: 'bg-green-50',
    medium: 'bg-green-100',
    text: 'text-green-600',
    border: 'border-green-200'
  },
  valet: {
    light: 'bg-blue-50',
    medium: 'bg-blue-100',
    text: 'text-blue-600',
    border: 'border-blue-200'
  },
  part_support: {
    light: 'bg-orange-50',
    medium: 'bg-orange-100',
    text: 'text-orange-600',
    border: 'border-orange-200'
  }
}
</script>