<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Header with Month Selector -->
      <div class="flex flex-col md:flex-row justify-between items-center mb-8">
        <div>
          <h1 class="text-2xl font-semibold text-gray-900">Dashboard Summary SOP</h1>
          <p class="text-gray-500 mt-1">
            Performance monitoring dan analisis sampling
          </p>
        </div>
        <div class="flex items-center gap-3 mt-4 md:mt-0">
          <button 
            @click="previousMonth"
            class="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ChevronLeftIcon class="w-5 h-5" />
          </button>
          <input 
            type="month" 
            v-model="selectedMonth"
            class="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 min-w-[160px]"
            :max="currentMonth"
          />
          <button 
            @click="nextMonth"
            class="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors disabled:opacity-50"
            :disabled="isCurrentMonth"
          >
            <ChevronRightIcon class="w-5 h-5" />
          </button>
        </div>
      </div>

      <!-- Main Metrics Cards -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div class="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl p-6 text-white">
          <div class="flex items-start justify-between">
            <div>
              <p class="text-blue-100">Total Sampling</p>
              <p class="text-3xl font-bold mt-2">{{ summary.total.total }}</p>
            </div>
            <ClipboardDocumentListIcon class="w-8 h-8 text-blue-300" />
          </div>
          <div class="mt-4 text-sm text-blue-100">
            {{ selectedMonth }}
          </div>
        </div>

        <div class="bg-gradient-to-br from-green-500 to-green-600 rounded-xl p-6 text-white">
          <div class="flex items-start justify-between">
            <div>
              <p class="text-green-100">Pass Rate</p>
              <p class="text-3xl font-bold mt-2">{{ summary.total.pass_rate.toFixed(1) }}%</p>
            </div>
            <CheckBadgeIcon class="w-8 h-8 text-green-300" />
          </div>
          <div class="mt-4 text-sm text-green-100">
            {{ summary.total.pass }} passed samplings
          </div>
        </div>

        <div class="bg-gradient-to-br from-red-500 to-red-600 rounded-xl p-6 text-white">
          <div class="flex items-start justify-between">
            <div>
              <p class="text-red-100">Fail Rate</p>
              <p class="text-3xl font-bold mt-2">{{ summary.total.fail_rate.toFixed(1) }}%</p>
            </div>
            <XCircleIcon class="w-8 h-8 text-red-300" />
          </div>
          <div class="mt-4 text-sm text-red-100">
            {{ summary.total.fail }} failed samplings
          </div>
        </div>

        <div class="bg-gradient-to-br from-violet-500 to-violet-600 rounded-xl p-6 text-white">
          <div class="flex items-start justify-between">
            <div>
              <p class="text-violet-100">Total Staff</p>
              <p class="text-3xl font-bold mt-2">{{ getTotalStaff }}</p>
            </div>
            <UsersIcon class="w-8 h-8 text-violet-300" />
          </div>
          <div class="mt-4 text-sm text-violet-100">
            Across all roles
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div class="bg-white rounded-xl border p-6 shadow-sm">
          <h3 class="text-lg font-medium mb-6">Overall Pass/Fail Distribution</h3>
          <div class="h-64">
            <PieChart 
              :data="passFailData"
              :options="pieChartOptions"
            />
          </div>
        </div>

        <div class="bg-white rounded-xl border p-6 shadow-sm">
          <h3 class="text-lg font-medium mb-6">Performance by Role</h3>
          <div class="h-64">
            <BarChart 
              :data="roleComparisonData"
              :options="barChartOptions"
            />
          </div>
        </div>
      </div>

      <!-- Role Performance Card with Top 3 + Load More -->
      <RolePerformanceCard 
      v-for="(roleData, roleKey) in summary.roles" 
      :key="roleKey"
      :roleKey="roleKey"
      :roleData="roleData"
    />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { 
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  CheckBadgeIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
  UsersIcon,
  TruckIcon,
  CubeIcon,
  CheckCircleIcon,
  TrophyIcon 
} from '@heroicons/vue/24/outline'
import { Toaster, toast } from 'vue-sonner'
import apiClient from '../../config/api'
import { Bar as BarChart, Pie as PieChart } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import RolePerformanceCard from './RolePerformanceCard.vue'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

const props = defineProps({
  roleKey: {
    type: String,
    required: true
  },
  roleData: {
    type: Object,
    required: true
  }
})
const showMore = ref(false)

// Computed properties dengan defensive checking
const visibleStaff = computed(() => {
  if (!props.roleData?.details) return []
  return showMore.value ? props.roleData.details : props.roleData.details.slice(0, 3)
})

const hasMoreStaff = computed(() => {
  return props.roleData?.details?.length > 3
})

const remainingStaffCount = computed(() => {
  if (!props.roleData?.details) return 0
  return props.roleData.details.length - 3
})

const getRoleLabel = computed(() => {
  const labelMap = {
    service_advisor: 'Service Advisor',
    mechanic: 'Mechanic',
    valet: 'Valet Parking',
    part_support: 'Part Support'
  }
  return labelMap[props.roleKey] || props.roleKey
})

const getRoleIcon = computed(() => {
  const iconMap = {
    service_advisor: UserCircleIcon,
    mechanic: WrenchScrewdriverIcon,
    valet: TruckIcon,
    part_support: CubeIcon
  }
  return iconMap[props.roleKey] || UserCircleIcon
})

// Helper functions
const formatPercentage = (value) => {
  if (!value && value !== 0) return '0.0%'
  return value.toFixed(1) + '%'
}

const toggleShowMore = () => {
  showMore.value = !showMore.value
}

const getTrophyClass = (rank) => {
  const baseClasses = 'bg-opacity-20'
  switch (rank) {
    case 1: return `bg-yellow-500 text-yellow-600 ${baseClasses}`
    case 2: return `bg-gray-400 text-gray-600 ${baseClasses}`
    case 3: return `bg-amber-600 text-amber-700 ${baseClasses}`
    default: return ''
  }
}

// Role colors dengan optional chaining
const roleColors = {
  service_advisor: {
    light: 'bg-purple-50',
    medium: 'bg-purple-100',
    text: 'text-purple-600',
    border: 'border-purple-200'
  },
  mechanic: {
    light: 'bg-green-50',
    medium: 'bg-green-100',
    text: 'text-green-600',
    border: 'border-green-200'
  },
  valet: {
    light: 'bg-blue-50',
    medium: 'bg-blue-100',
    text: 'text-blue-600',
    border: 'border-blue-200'
  },
  part_support: {
    light: 'bg-orange-50',
    medium: 'bg-orange-100',
    text: 'text-orange-600',
    border: 'border-orange-200'
  }
}

// State
const loading = ref(false)
const selectedMonth = ref(new Date().toISOString().slice(0, 7))
const summary = ref({
  total: {
    total: 0,
    pass: 0,
    fail: 0,
    pass_rate: 0,
    fail_rate: 0
  },
  roles: {
    service_advisor: {
      total: 0,
      pass: 0,
      fail: 0,
      pass_rate: 0,
      fail_rate: 0,
      label: 'Service Advisor',
      details: []
    },
    mechanic: {
      total: 0,
      pass: 0,
      fail: 0,
      pass_rate: 0,
      fail_rate: 0,
      label: 'Mechanic',
      details: []
    },
    valet: {
      total: 0,
      pass: 0,
      fail: 0,
      pass_rate: 0,
      fail_rate: 0,
      label: 'Valet Parking',
      details: []
    },
    part_support: {
      total: 0,
      pass: 0,
      fail: 0,
      pass_rate: 0,
      fail_rate: 0,
      label: 'Part Support',
      details: []
    }
  }
})

// Chart Data dengan data yang lebih komprehensif
const passFailData = computed(() => ({
  labels: ['Pass', 'Fail'],
  datasets: [{
    data: [
      summary.value.total.pass,
      summary.value.total.fail
    ],
    backgroundColor: ['#22C55E', '#EF4444'],
    borderWidth: 0
  }]
}))

const roleComparisonData = computed(() => ({
  labels: Object.values(summary.value.roles).map(role => role.label),
  datasets: [
    {
      label: 'Pass',
      data: Object.values(summary.value.roles).map(role => role.pass),
      backgroundColor: '#22C55E'
    },
    {
      label: 'Fail',
      data: Object.values(summary.value.roles).map(role => role.fail),
      backgroundColor: '#EF4444'
    }
  ]
}))

// Computed
const currentMonth = computed(() => new Date().toISOString().slice(0, 7))
const isCurrentMonth = computed(() => selectedMonth.value === currentMonth.value)

// Computed for total staff across all roles
const getTotalStaff = computed(() => {
  return Object.values(summary.value.roles).reduce((total, role) => {
    return total + role.details.length
  }, 0)
})


// Enhanced chart options
const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        padding: 20,
        font: {
          size: 12
        }
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || ''
          const value = context.raw || 0
          const total = context.dataset.data.reduce((a, b) => a + b, 0)
          const percentage = ((value / total) * 100).toFixed(1)
          return `${label}: ${value} (${percentage}%)`
        }
      }
    }
  }
}

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        font: {
          size: 11
        }
      },
      grid: {
        display: true,
        drawBorder: false
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 11
        }
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        padding: 20,
        font: {
          size: 12
        }
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || ''
          const value = context.raw || 0
          return `${label}: ${value} samplings`
        }
      }
    }
  }
}

// Improved fetch function with error handling
const fetchSummary = async () => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/summary', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        month: selectedMonth.value
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      summary.value = response.data.result.data
      toast.success('Data berhasil dimuat')
    } else {
      throw new Error(response?.data?.result?.message || 'Gagal memuat data summary')
    }
  } catch (error) {
    console.error('Error fetching summary:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

// Helper functions for date navigation
const previousMonth = () => {
  const date = new Date(selectedMonth.value + '-01')
  date.setMonth(date.getMonth() - 1)
  selectedMonth.value = date.toISOString().slice(0, 7)
}

const nextMonth = () => {
  if (isCurrentMonth.value) return
  const date = new Date(selectedMonth.value + '-01')
  date.setMonth(date.getMonth() + 1)
  selectedMonth.value = date.toISOString().slice(0, 7)
}

// Format number helper
const formatNumber = (num) => {
  return new Intl.NumberFormat('id-ID').format(num)
}

// Additional computed for trend indicators
const getTrendIndicator = (current, previous) => {
  if (!previous) return 'neutral'
  return current > previous ? 'up' : current < previous ? 'down' : 'neutral'
}

// Watch for month changes
watch(selectedMonth, () => {
  fetchSummary()
})

// Initialize on mount
onMounted(() => {
  fetchSummary()
})
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>