<template>
  <div class="p-6">
    <!-- Toaster untuk notifications -->
    <Toaster 
      position="top-right"
      :duration="3000"
      :richColors="true"
    />

    <!-- Main Content Container -->
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header Section -->
        <div class="flex items-center justify-between gap-4">
          <h1 class="text-2xl font-semibold text-gray-900">SOP Sampling</h1>
          
          <!-- Filter Section -->
          <div class="flex items-center gap-4">
            <!-- Search Input -->
            <div class="relative">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="Cari order, plat nomor..."
                class="pl-10 pr-4 py-2 border rounded-lg w-80"
                @input="handleSearch"
              >
              <MagnifyingGlassIcon class="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            <!-- Role Filter -->
            <div class="flex rounded-lg border overflow-hidden">
              <button
                v-for="role in roleOptions"
                :key="role.value"
                @click="() => handleRoleChange(role.value)"
                class="px-4 py-2 text-sm border-l first:border-l-0"
                :class="[
                  selectedRole === role.value 
                    ? 'bg-blue-50 text-blue-600 font-medium' 
                    : 'hover:bg-gray-50'
                ]"
              >
                {{ role.label }}
              </button>
            </div>
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="text-left py-3 px-4 font-medium text-gray-700">No. Order</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Info Mobil</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Service Advisor</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Mekanik</th>
                <th class="text-center py-3 px-4 font-medium text-gray-700">Jumlah Sampling</th>
                <th class="text-center py-3 px-4 font-medium text-gray-700">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="order in orders" 
                :key="order.id"
                class="border-t hover:bg-gray-50"
              >
                <!-- Order Number -->
                <td class="py-3 px-4">
                  <div class="font-medium">{{ order.name }}</div>
                  <div class="text-sm text-gray-500">
                    {{ formatDate(order.date) }}
                  </div>
                </td>

                <!-- Car Info -->
                <td class="py-3 px-4">
                  <div class="space-y-1">
                    <div class="font-medium">{{ order.car_info.plate }}</div>
                    <div class="text-sm text-gray-500">
                      {{ order.car_info.brand }} {{ order.car_info.type }}
                    </div>
                  </div>
                </td>

                <!-- Service Advisor -->
                <td class="py-3 px-4">
                  <div v-if="order.employee_info?.service_advisor?.length" class="space-y-1">
                    <div 
                      v-for="sa in order.employee_info.service_advisor" 
                      :key="sa.id"
                      class="flex items-center space-x-2 text-sm"
                    >
                      <span class="font-medium">{{ sa.name }}</span>
                      <span 
                        v-if="sa.sampling_count > 0"
                        class="px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full text-xs"
                      >
                        {{ sa.sampling_count }} samples
                      </span>
                    </div>
                  </div>
                </td>

                <!-- Mechanic -->
                <td class="py-3 px-4">
                  <div v-if="order.employee_info?.mechanic?.length" class="space-y-1">
                    <div 
                      v-for="mech in order.employee_info.mechanic" 
                      :key="mech.id"
                      class="flex items-center space-x-2 text-sm"
                    >
                      <span class="font-medium">{{ mech.name }}</span>
                      <span 
                        v-if="mech.sampling_count > 0"
                        class="px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full text-xs"
                      >
                        {{ mech.sampling_count }} samples
                      </span>
                    </div>
                  </div>
                </td>

                <!-- Sampling Count -->
                <td class="py-3 px-4">
                  <div class="flex justify-center">
                    <div class="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                      {{ order.sampling_count }}
                    </div>
                  </div>
                </td>

                <!-- Actions -->
                <td class="py-3 px-4">
                  <div class="flex justify-center">
                    <div class="relative flex rounded-lg overflow-hidden">
                      <!-- View Existing -->
                      <button
                        v-if="order.sampling_count > 0"
                        @click="viewSamplingDetails(order)"
                        class="px-4 py-2 bg-green-500 text-white hover:bg-green-600 transition-colors flex items-center space-x-2"
                      >
                        <EyeIcon class="w-4 h-4" />
                        <span>Lihat Detil</span>
                        <span class="px-2 py-0.5 bg-green-600 rounded-full text-xs">
                          {{ order.sampling_count }}
                        </span>
                      </button>

                      <!-- Add More -->
                      <button
                        v-if="order.sampling_count > 0"
                        @click="openSamplingDialog(order)"
                        class="px-3 py-2 bg-green-500 text-white hover:bg-green-600 border-l border-green-600"
                      >
                        <PlusIcon class="w-4 h-4" />
                      </button>

                      <!-- Create New -->
                      <button
                        v-else
                        @click="openSamplingDialog(order)"
                        class="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors flex items-center space-x-2"
                      >
                        <ClipboardDocumentListIcon class="w-4 h-4" />
                        <span>Pilih Sampling</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination section -->
        <!-- Pagination section -->
        <div 
        v-if="pagination"
        class="flex flex-col md:flex-row justify-between items-center gap-4 py-4"
        >
        <div class="flex items-center gap-4">
          <span class="text-sm text-gray-600">
            Menampilkan {{ startNumber }} sampai {{ endNumber }} 
            dari {{ pagination.total_items }} data
          </span>
          
          <select
            v-model="itemsPerPage"
            @change="handleItemsPerPageChange"
            class="border rounded-lg px-3 py-1.5 text-sm bg-white"
          >
            <option :value="10">10 per halaman</option>
            <option :value="20">20 per halaman</option>
            <option :value="50">50 per halaman</option>
            <option :value="100">100 per halaman</option>
          </select>
        </div>

        <div class="flex items-center gap-2">
          <!-- Previous Button -->
          <button
            @click="handlePageChange(pagination.current_page - 1)"
            :disabled="pagination.current_page <= 1"
            class="px-3 py-1.5 rounded-lg border text-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Sebelumnya
          </button>
          
          <!-- Page Numbers -->
          <div class="flex gap-2">
            <template v-for="page in pageNumbers" :key="page">
              <template v-if="page === '...'">
                <span class="px-3 py-1.5 text-gray-500">...</span>
              </template>
              <template v-else>
                <button
                  @click="handlePageChange(page)"
                  class="min-w-[40px] px-3 py-1.5 border rounded-lg text-sm"
                  :class="pagination.current_page === page 
                    ? 'bg-blue-50 border-blue-500 text-blue-600 font-medium' 
                    : 'hover:bg-gray-50'"
                >
                  {{ page }}
                </button>
              </template>
            </template>
          </div>

          <!-- Next Button -->
          <button
            @click="handlePageChange(pagination.current_page + 1)"
            :disabled="pagination.current_page >= pagination.total_pages"
            class="px-3 py-1.5 rounded-lg border text-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Selanjutnya
          </button>
        </div>
        </div>
      </div>
    </div>

    <!-- Modal Components -->
     <!-- Modal Components - Lanjutan template sebelumnya -->

    <!-- Create Sampling Dialog -->
    <TransitionRoot appear :show="showSamplingDialog" as="template">
      <Dialog as="div" @close="closeSamplingDialog" class="relative z-50">
        <!-- Backdrop -->
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" />
        </TransitionChild>
  
        <!-- Full screen container -->
        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-5xl bg-white rounded-xl shadow-xl">
                <!-- Header Section -->
                <div class="border-b">
                  <div class="px-8 py-5">
                    <DialogTitle class="text-xl font-semibold text-gray-900">
                      Create Sampling
                    </DialogTitle>
                  </div>
    
              <!-- Order Info Summary -->
              <div class="px-8 py-4 bg-gray-50 border-b">
                <div class="flex justify-between items-center">
                  <div>
                    <span class="text-sm text-gray-500">Order Number</span>
                    <h3 class="text-lg font-semibold mt-1">{{ selectedOrder?.name }}</h3>
                  </div>
                  <div class="flex gap-12">
                    <div>
                      <span class="text-sm text-gray-500">Vehicle</span>
                      <div class="mt-1">
                        <div class="font-semibold">{{ selectedOrder?.car_info?.plate }}</div>
                        <div class="text-sm text-gray-600">
                          {{ selectedOrder?.car_info?.brand }} {{ selectedOrder?.car_info?.type }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <span class="text-sm text-gray-500">Service Date</span>
                      <div class="font-semibold mt-1">{{ formatDate(selectedOrder?.date) }}</div>
                    </div>
                  </div>
                </div>
              </div>
    
              <form @submit.prevent="handleCreateSampling" class="px-8 py-6">
                <!-- SOP Selection Section -->
                <div class="mb-8">
                  <div class="flex justify-between items-center mb-4">
                    <div>
                      <h4 class="text-lg font-semibold">Select SOP</h4>
                      <p class="text-sm text-gray-500 mt-1">Choose the SOPs for sampling process</p>
                    </div>
                    <div class="flex items-center gap-4">
                      <!-- Search -->
                      <div class="relative">
                        <input
                          v-model="sopSearchQuery"
                          type="text"
                          placeholder="Search SOP..."
                          class="w-64 pl-10 pr-4 py-2 text-sm border rounded-lg"
                        >
                        <MagnifyingGlassIcon class="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      </div>
                      
                      <!-- Select All -->
                      <button
                        type="button"
                        class="text-sm font-medium text-blue-600 hover:text-blue-800"
                        @click="toggleAllSOPs"
                      >
                        {{ isAllSOPsSelected ? 'Deselect All' : 'Select All' }}
                      </button>
    
                      <!-- Role Filter -->
                      <div class="flex rounded-lg border overflow-hidden shadow-sm">
                        <template v-for="role in roleOptions" :key="role.value">
                          <button
                            type="button"
                            @click="sopFilter = role.value"
                            class="px-4 py-2 text-sm font-medium border-l first:border-l-0 transition-colors"
                            :class="sopFilter === role.value ? 'bg-blue-50 text-blue-600 border-blue-200' : 'hover:bg-gray-50'"
                          >
                            {{ role.label }}
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
    
                  <!-- SOP List -->
                  <div class="max-h-[280px] overflow-y-auto border rounded-lg divide-y">
                    <div 
                      v-for="sop in filteredSOPs" 
                      :key="sop.id"
                      class="p-4 hover:bg-gray-50"
                    >
                      <div class="flex items-start gap-4">
                        <input
                          type="checkbox"
                          v-model="selectedSOPs"
                          :value="sop.id"
                          :id="`sop-${sop.id}`"
                          class="mt-1.5 h-4 w-4 rounded border-gray-300"
                          @change="(e) => handleSOPSelection(e, sop)"
                        >
                        <label :for="`sop-${sop.id}`" class="flex-1 cursor-pointer">
                          <div class="font-medium">{{ sop.code }} - {{ sop.name }}</div>
                          <div class="text-sm text-gray-500 mt-1">{{ sop.description }}</div>
                          <span 
                            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-2"
                            :class="{
                              'bg-purple-100 text-purple-800': sop.role === 'sa',
                              'bg-green-100 text-green-800': sop.role === 'mechanic',
                              'bg-blue-100 text-blue-800': sop.role === 'valet',
                              'bg-orange-100 text-orange-800': sop.role === 'part_support'
                            }"
                          >
                            {{ sop.role_label }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
    
                <!-- Employee Selection Section -->
                <div v-if="needsEmployeeSelection" class="mb-8">
                  <h4 class="text-lg font-semibold mb-4">Staff Assignment</h4>
                  
                  <div 
                    v-for="sopId in selectedSOPs" 
                    :key="sopId"
                    class="bg-gray-50 p-6 rounded-xl border mb-4 last:mb-0"
                    v-show="isRoleValet(sopId) || isRolePartSupport(sopId)"
                  >
                     <!-- SOP Info -->
                    <div class="flex justify-between items-start mb-4">
                      <div>
                        <div class="text-lg font-medium">{{ getSOPDetails(sopId).name }}</div>
                        <div class="text-sm text-gray-500">{{ getSOPDetails(sopId).code }}</div>
                      </div>
                      <div class="flex items-center gap-2">
                        <!-- Status Badge -->
                        <span 
                          class="px-2 py-1 rounded-full text-xs font-medium"
                          :class="{
                            'bg-green-100 text-green-800': employeeSelections[sopId],
                            'bg-orange-100 text-orange-800': !employeeSelections[sopId]
                          }"
                        >
                          {{ employeeSelections[sopId] ? 'Staff Selected' : 'Staff Required' }}
                        </span>
                        <!-- Role Badge -->
                        <span 
                          class="px-3 py-1 rounded-full text-sm font-medium"
                          :class="getRoleBadgeClasses(getSOPDetails(sopId).role)"
                        >
                          {{ getSOPDetails(sopId).role_label }}
                        </span>
                      </div>
                    </div>
    
                    <!-- Search & Filter -->
                    <div class="flex gap-3 mb-4">
                      <div class="relative flex-1">
                        <input
                          v-model="employeeSearchQueries[sopId]"
                          type="text"
                          class="w-full pl-10 pr-4 py-2.5 text-sm border rounded-lg"
                          placeholder="Search staff by name..."
                        >
                        <MagnifyingGlassIcon class="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                      </div>
                      
                      <select
                        v-model="employeeDepartmentFilters[sopId]"
                        class="min-w-[200px] px-4 py-2.5 text-sm border rounded-lg"
                      >
                        <option value="">All Departments</option>
                        <option 
                          v-for="dept in availableDepartments" 
                          :key="dept.id"
                          :value="dept.id"
                        >
                          {{ dept.name }}
                        </option>
                      </select>
                    </div>
    
                    <!-- Employee List -->
                    <div class="border rounded-lg bg-white shadow-sm">
                      <div class="max-h-[320px] overflow-y-auto divide-y">
                        <label
                          v-for="employee in getFilteredEmployees(sopId)"
                          :key="employee.id"
                          :for="`employee-${sopId}-${employee.id}`"
                          class="flex items-center p-4 hover:bg-gray-50 cursor-pointer transition-colors"
                        >
                          <input
                            type="radio"
                            :id="`employee-${sopId}-${employee.id}`"
                            :name="`employee-${sopId}`"
                            :value="employee.id"
                            v-model="employeeSelections[sopId]"
                            class="mr-4 h-4 w-4"
                          >
                          <div>
                            <div class="font-medium">{{ employee.name }}</div>
                            <div class="text-sm text-gray-500 mt-0.5">
                              {{ employee.department?.name }} • {{ employee.job_title }}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
    
                    <!-- Validation Message -->
                    <div 
                      v-if="!employeeSelections[sopId]" 
                      class="flex items-center gap-2 text-orange-600 mt-3"
                    >
                      <ExclamationCircleIcon class="h-5 w-5" />
                      <span class="text-sm font-medium">Staff selection is required</span>
                    </div>
                  </div>
                </div>
    
                <!-- Notes Section -->
                <div class="mb-8">
                  <label class="block mb-2">
                    <span class="text-sm font-medium text-gray-900">Notes</span>
                    <span class="text-sm text-gray-500 ml-1">(optional)</span>
                  </label>
                  <textarea
                    v-model="samplingForm.notes"
                    rows="3"
                    class="w-full px-4 py-3 text-sm border rounded-lg resize-none"
                    placeholder="Add any additional notes..."
                  ></textarea>
                </div>
    
                <!-- Action Buttons -->
                <div class="flex justify-end gap-3 pt-4 border-t">
                  <button
                    type="button"
                    @click="closeSamplingDialog"
                    class="px-6 py-2.5 border rounded-lg hover:bg-gray-50 font-medium"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    :disabled="loading || !isFormValid"
                    class="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 font-medium flex items-center gap-2"
                  >
                    <span>Create Sampling</span>
                    <div v-if="loading" class="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                  </button>
                </div>
              </form>
              </div>

            </DialogPanel>
          </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Details Dialog -->
    <SamplingDetailsDialog
      :is-open="showSamplingDetailsDialog"
      :selected-order="selectedOrder"
      :on-close="() => showSamplingDetailsDialog = false"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { Toaster, toast } from 'vue-sonner'
import apiClient from '../../config/api'
import debounce from 'lodash/debounce'
import SamplingDetailsDialog from './SamplingDetailModal.vue'

import { 
  ClipboardDocumentListIcon, 
  EyeIcon, 
  PlusIcon,
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
  ArrowRightIcon,
  UserIcon
} from '@heroicons/vue/24/outline'

// Core state
const loading = ref(false)
const orders = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const selectedRole = ref('')
const showSamplingDialog = ref(false)
const selectedOrder = ref(null)
const showSamplingDetailsDialog = ref(false)
const itemsPerPage = ref(20)
const selectedOrders = ref([])
// Tambahkan state untuk tracking SOP yang sudah ada
const existingSopIds = ref(new Set())


// SOP state
const availableSOPs = ref([])
const selectedSOPs = ref([])
const sopFilter = ref('')
const sopSearchQuery = ref('')

// Employee state
const availableEmployees = ref([])
const availableDepartments = ref([])
const employeeSearchQueries = ref({})
const employeeDepartmentFilters = ref({})
const employeeSelections = ref({})
const employeePagination = ref({
  currentPage: 1,
  hasMore: true,
  loading: false
})

// tableHeaders
const tableHeaders = [
  { key: 'order_number', label: 'Order Number' },
  { key: 'car_info', label: 'Car Info' },
  { key: 'sampling_count', label: 'Sampling Count' },
  { key: 'actions', label: 'Actions', width: '48' }
]

// Form state
const samplingForm = ref({
  notes: ''
})

// Role options
const roleOptions = [
  { value: 'sa', label: 'Service Advisor' },
  { value: 'mechanic', label: 'Mechanic' },
  { value: 'valet', label: 'Valet' },
  { value: 'part_support', label: 'Part Support' }
]

// Computed properties
const filteredSOPs = computed(() => {
  let filtered = availableSOPs.value
  
  if (sopFilter.value) {
    filtered = filtered.filter(sop => sop.role === sopFilter.value)
  }
  
  if (sopSearchQuery.value) {
    const query = sopSearchQuery.value.toLowerCase()
    filtered = filtered.filter(sop => 
      sop.name.toLowerCase().includes(query) || 
      sop.code.toLowerCase().includes(query) ||
      sop.description?.toLowerCase().includes(query)
    )
  }
  
  return filtered
})

const needsEmployeeSelection = computed(() => {
  return selectedSOPs.value.some(sopId => {
    const sopDetails = getSOPDetails(sopId)
    return ['valet', 'part_support'].includes(sopDetails?.role)
  })
})

const isAllSOPsSelected = computed(() => {
  return filteredSOPs.value.length > 0 && 
         filteredSOPs.value.every(sop => selectedSOPs.value.includes(sop.id))
})

// Helper functions
const getSOPDetails = (sopId) => {
  return availableSOPs.value.find(sop => sop.id === sopId)
}

const isRoleValet = (sopId) => {
  const sop = getSOPDetails(sopId)
  return sop?.role === 'valet'
}

const isRolePartSupport = (sopId) => {
  const sop = getSOPDetails(sopId)
  return sop?.role === 'part_support'
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  date.setHours(date.getHours() + 7)
  
  return date.toLocaleDateString('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Employee handling functions
const fetchEmployees = async () => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/employees', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        search: "",
        department_id: "",
        page: 1,
        limit: 50,
        sort_by: "name",
        sort_order: "asc"
      }
    })

    if (response?.data?.result?.status === 'success') {
      // Simpan semua employees
      availableEmployees.value = response.data.result.data.rows
      
      // Extract departments
      const departments = new Set()
      availableEmployees.value.forEach(emp => {
        if (emp.department?.id && emp.department?.name) {
          departments.add(JSON.stringify({
            id: emp.department.id,
            name: emp.department.name
          }))
        }
      })
      
      availableDepartments.value = Array.from(departments)
        .map(dept => JSON.parse(dept))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  } catch (error) {
    console.error('Error fetching employees:', error)
    toast.error('Gagal memuat daftar staff')
  } finally {
    loading.value = false
  }
}

const getFilteredEmployees = (sopId) => {
  const searchQuery = employeeSearchQueries.value[sopId]?.toLowerCase() || ''
  const departmentId = employeeDepartmentFilters.value[sopId]
  const sopDetails = getSOPDetails(sopId)

  if (!availableEmployees.value.length) return []

  return availableEmployees.value.filter(employee => {
    if (!employee) return false

    // Role-based filtering
    const jobTitle = String(employee.job_title || '').toLowerCase()
    const position = String(employee.position?.name || '').toLowerCase()
    const department = String(employee.department?.name || '').toLowerCase()

    // Filter berdasarkan role
    if (sopDetails?.role === 'valet') {
      if (!jobTitle.includes('valet') && !position.includes('valet') && !department.includes('valet')) {
        return false
      }
    } else if (sopDetails?.role === 'part_support') {
      if (!jobTitle.includes('part') && !position.includes('part') && !department.includes('part')) {
        return false
      }
    }

    // Search filter
    if (searchQuery) {
      const name = String(employee.name || '').toLowerCase()
      if (!name.includes(searchQuery)) {
        return false
      }
    }

    // Department filter
    if (departmentId && employee.department?.id) {
      if (String(employee.department.id) !== String(departmentId)) {
        return false
      }
    }

    return true
  })
}

// Handle employee search
const handleEmployeeSearch = debounce((sopId) => {
  // Tidak perlu melakukan apa-apa karena data sudah di-load semua
  // Filter akan otomatis terjadi melalui computed property
}, 300)

// Remove handle scroll karena sudah tidak diperlukan

// Tambahkan function ini di script setup
const getRoleBadgeClasses = (role) => {
  const baseClasses = 'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium'
  switch (role) {
    case 'sa':
      return `${baseClasses} bg-purple-100 text-purple-800`
    case 'mechanic':
      return `${baseClasses} bg-green-100 text-green-800`
    case 'valet':
      return `${baseClasses} bg-blue-100 text-blue-800`
    case 'part_support':
      return `${baseClasses} bg-orange-100 text-orange-800`
    default:
      return `${baseClasses} bg-gray-100 text-gray-800`
  }
}

// const getFilteredEmployees = (sopId) => {
//   const searchQuery = employeeSearchQueries.value[sopId]?.toLowerCase() || ''
//   const departmentId = employeeDepartmentFilters.value[sopId]
//   const sopDetails = getSOPDetails(sopId)

//   if (!availableEmployees.value.length) return []

//   // Pre-filter berdasarkan role dulu
//   let roleFilteredEmployees = availableEmployees.value.filter(employee => {
//     if (!employee) return false

//     const jobTitle = String(employee.job_title || '').toLowerCase()
//     const position = String(employee.position?.name || '').toLowerCase()
//     const department = String(employee.department?.name || '').toLowerCase()

//     if (sopDetails?.role === 'valet') {
//       return jobTitle.includes('valet') || position.includes('valet') || department.includes('valet')
//     }
    
//     if (sopDetails?.role === 'part_support') {
//       return jobTitle.includes('part') || position.includes('part') || department.includes('part')
//     }

//     return false
//   })

//   // Kemudian filter berdasarkan search dan department
//   return roleFilteredEmployees.filter(employee => {
//     if (!employee) return false
    
//     // Debug employee
//     console.log('Filtering employee:', {
//       id: employee.id,
//       name: employee.name,
//       jobTitle: employee.job_title,
//       position: employee.position?.name,
//       department: employee.department?.name
//     })

//     // Role-based filtering
//     const jobTitle = String(employee.job_title || '').toLowerCase()
//     const position = String(employee.position?.name || '').toLowerCase()
//     const department = String(employee.department?.name || '').toLowerCase()

//     // Debug role matching
//     console.log('Checking role match:', {
//       sopRole: sopDetails?.role,
//       jobTitle,
//       position,
//       department
//     })

//     // For Valet role
//     if (sopDetails?.role === 'valet') {
//       const isValet = jobTitle.includes('valet') || 
//                      position.includes('valet') || 
//                      department.includes('valet')
//       if (!isValet) {
//         return false
//       }
//     }
    
//     // For Part Support role
//     if (sopDetails?.role === 'part_support') {
//       const isPartSupport = jobTitle.includes('part') || 
//                            position.includes('part') || 
//                            department.includes('part')
//       if (!isPartSupport) {
//         return false
//       }
//     }

//     // Search filter
//     if (searchQuery) {
//       const name = String(employee.name || '').toLowerCase()
//       if (!name.includes(searchQuery)) {
//         return false
//       }
//     }

//     // Department filter
//     if (departmentId && employee.department?.id) {
//       if (String(employee.department.id) !== String(departmentId)) {
//         return false
//       }
//     }

//     return true
//   })

//   // Debug results
//   console.log('Filtered employees count:', filtered.length)
  
//   return filtered
// }


// const handleEmployeeSearch = debounce((sopId) => {
//   const filtered = getFilteredEmployees(sopId)
//   // If we have few results and more employees can be loaded, trigger infinite loading
//   if (filtered.length < 10 && employeePagination.value.hasMore) {
//     fetchEmployees(true)
//   }
// }, 300)

const handleEmployeeScroll = async (event) => {
  const element = event.target
  if (element.scrollHeight - element.scrollTop <= element.clientHeight * 1.5) {
    await fetchEmployees(true)
  }
}

// SOP handling functions
const fetchSOPs = async () => {
  try {
    const response = await apiClient.post('/web/sop/master/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: 1,
        limit: 100,
        role: selectedRole.value || null
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      availableSOPs.value = response.data.result.data.rows
    }
  } catch (error) {
    console.error('Error fetching SOPs:', error)
    toast.error('Gagal memuat daftar SOP')
  }
}

const toggleAllSOPs = () => {
  if (isAllSOPsSelected.value) {
    // Jika deselect all, hapus semua
    selectedSOPs.value = []
    employeeSelections.value = {}
  } else {
    // Jika select all, preserve existing selections
    const currentSelections = { ...employeeSelections.value }
    selectedSOPs.value = filteredSOPs.value.map(sop => sop.id)
    employeeSelections.value = currentSelections
  }
}

// Core data fetching
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/available-orders', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        role: selectedRole.value || null
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      orders.value = response.data.result.data.rows
      pagination.value = response.data.result.data.pagination
    }
    if (response?.data?.result?.status === 'success') {
      const { rows: orderRows, pagination: paginationData } = response.data.result.data
      orders.value = orderRows.map(order => ({
        ...order,
        isSelected: selectedOrders.value.includes(order.id)
      }))
      pagination.value = paginationData
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch data')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

// 2. Perbaiki openSamplingDialog untuk mempertahankan state yang sudah ada
const openSamplingDialog = async (order) => {
  try {
    selectedOrder.value = order
    samplingForm.value.notes = ''
    
    // Reset hanya search dan filter
    employeeSearchQueries.value = {}
    employeeDepartmentFilters.value = {}
    
    // Load employees jika belum ada
    if (!availableEmployees.value.length) {
      await fetchEmployees()
    }
    
    if (order.sampling_count > 0) {
      // Preserve existing selections sebelum fetch
      const existingSelections = { ...employeeSelections.value }
      await fetchExistingSamplings(order.id)
      // Gabungkan dengan selections yang baru
      employeeSelections.value = { 
        ...existingSelections, 
        ...employeeSelections.value 
      }
    }
    
    showSamplingDialog.value = true
  } catch (error) {
    console.error('Error opening dialog:', error)
    toast.error('Gagal membuka dialog')
  }
}

// Reset existingSopIds saat dialog ditutup
const closeSamplingDialog = () => {
  showSamplingDialog.value = false
  selectedOrder.value = null
  selectedSOPs.value = []
  samplingForm.value.notes = ''
  employeeSearchQueries.value = {}
  employeeDepartmentFilters.value = {}
  employeeSelections.value = {}
  existingSopIds.value = new Set() // Reset existing SOP tracking
}

// Tambahkan computed untuk mengecek apakah employee sudah diselect
const isEmployeeSelected = computed(() => {
  return (sopId) => {
    const sop = getSOPDetails(sopId)
    if (!sop) return false
    
    if (['valet', 'part_support'].includes(sop.role)) {
      return Boolean(employeeSelections.value[sopId])
    }
    return true
  }
})


const viewSamplingDetails = (order) => {
  selectedOrder.value = order
  showSamplingDetailsDialog.value = true
}

// Form handling
const validateForm = () => {
  const invalidSOP = selectedSOPs.value.find(sopId => {
    const sop = getSOPDetails(sopId)
    if (['valet', 'part_support'].includes(sop?.role)) {
      return !employeeSelections.value[sopId]
    }
    return false
  })

  if (invalidSOP) {
    const sop = getSOPDetails(invalidSOP)
    toast.error(`Silahkan pilih ${sop.role_label} untuk ${sop.name}`)
    return false
  }

  return true
}

// Update handleCreateSampling untuk mencegah pengiriman SOP yang sudah ada
const handleCreateSampling = async () => {
  if (!validateForm()) return
  
  try {
    loading.value = true

    // Filter out SOPs yang sudah ada
    const newSopIds = selectedSOPs.value.filter(sopId => !existingSopIds.value.has(sopId))

    if (newSopIds.length === 0) {
      toast.info('Tidak ada SOP baru yang perlu ditambahkan')
      closeSamplingDialog()
      return
    }

    // Handle masing-masing SOP baru secara terpisah
    const createPromises = newSopIds.map(sopId => {
      const sop = getSOPDetails(sopId)
      const params = {
        jsonrpc: "2.0",
        method: "call",
        params: {
          sale_order_id: selectedOrder.value.id,
          sop_id: sopId,
          notes: samplingForm.value.notes || ""
        }
      }

      // Tambahkan employee_ids jika SOP adalah valet atau part support
      if (['valet', 'part_support'].includes(sop?.role)) {
        const employeeId = employeeSelections.value[sopId]
        if (employeeId) {
          params.params.employee_ids = [employeeId]
        }
      }

      return apiClient.post('/web/sop/sampling/create', params)
    })

    // Jalankan semua request secara parallel
    const responses = await Promise.all(createPromises)

    // Cek hasil responses
    const hasError = responses.some(response => response?.data?.result?.status !== 'success')

    if (!hasError) {
      toast.success(`${newSopIds.length} sampling baru berhasil dibuat`)
      closeSamplingDialog()
      fetchData() // Refresh data
    } else {
      throw new Error('Gagal membuat beberapa sampling')
    }

  } catch (error) {
    console.error('Error creating sampling:', error)
    toast.error('Gagal membuat sampling: ' + error.message)
  } finally {
    loading.value = false
  }
}


// Event handlers
const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = () => {
  fetchData({ page: 1 })
  fetchSOPs()
}

const handlePageChange = (page) => {
  fetchData({ page })
}

// Add these computed properties
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const totalPages = pagination.value.total_pages
  const current = pagination.value.current_page
  const delta = 2 // Jumlah halaman yang ditampilkan sebelum dan sesudah halaman saat ini
  const pages = []
  
  // Always show first page
  pages.push(1)
  
  // Calculate range around current page
  let rangeStart = Math.max(2, current - delta)
  let rangeEnd = Math.min(totalPages - 1, current + delta)

  // Adjust range if current page is near the start
  if (current - delta <= 2) {
    rangeEnd = Math.min(4, totalPages - 1)
  }
  
  // Adjust range if current page is near the end
  if (current + delta >= totalPages - 1) {
    rangeStart = Math.max(totalPages - 3, 2)
  }

  // Add ellipsis and range start
  if (rangeStart > 2) {
    pages.push('...')
  }
  
  // Add pages in range
  for (let i = rangeStart; i <= rangeEnd; i++) {
    if (i > 1 && i < totalPages) {
      pages.push(i)
    }
  }
  
  // Add ellipsis and last page
  if (rangeEnd < totalPages - 1) {
    pages.push('...')
  }
  if (totalPages !== 1) {
    pages.push(totalPages)
  }

  return pages
})

// Method untuk handling perubahan items per page
const handleItemsPerPageChange = () => {
  fetchData({ page: 1, limit: itemsPerPage.value })
}

// Update computed property isFormValid
const isFormValid = computed(() => {
  // Harus ada SOP yang dipilih
  if (selectedSOPs.value.length === 0) return false

  // Cek setiap SOP yang dipilih apakah sudah memilih employee jika diperlukan
  const allEmployeesSelected = selectedSOPs.value.every(sopId => {
    const sop = getSOPDetails(sopId)
    if (!sop) return false

    // Untuk SOP valet dan part support, harus pilih employee
    if (['valet', 'part_support'].includes(sop.role)) {
      return Boolean(employeeSelections.value[sopId])
    }

    // Untuk SOP lain tidak perlu pilih employee
    return true
  })

  return allEmployeesSelected
})

// Update fetchExistingSamplings untuk menyimpan SOP yang sudah ada
const fetchExistingSamplings = async (orderId) => {
  try {
    const response = await apiClient.post('/web/sop/sampling/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: 1,
        limit: 25,
        search: selectedOrder.value.name,
        month: new Date(selectedOrder.value.date).toISOString().slice(0, 7),
        is_sa: null
      }
    })

    if (response?.data?.result?.status === 'success') {
      const existingSamplings = response.data.result.data.rows.filter(
        sampling => sampling.sale_order.id === orderId
      )

      // Reset existing SOP tracking
      existingSopIds.value = new Set()

      // Simpan data existing untuk referensi
      const existingSelections = { ...employeeSelections.value }

      existingSamplings.forEach(sampling => {
        // Track SOP yang sudah ada
        existingSopIds.value.add(sampling.sop.id)

        // Set selected SOPs
        if (!selectedSOPs.value.includes(sampling.sop.id)) {
          selectedSOPs.value.push(sampling.sop.id)
        }

        // Preserve existing selections atau set baru jika belum ada
        if (sampling.sop.role === 'valet' && sampling.employee_info?.valet?.[0]) {
          existingSelections[sampling.sop.id] = sampling.employee_info.valet[0].id
        }
        if (sampling.sop.role === 'part_support' && sampling.employee_info?.part_support?.[0]) {
          existingSelections[sampling.sop.id] = sampling.employee_info.part_support[0].id
        }
      })

      // Update employeeSelections dengan menggabungkan data yang ada
      employeeSelections.value = { ...existingSelections }

      console.log('Existing SOPs:', [...existingSopIds.value])
    }
  } catch (error) {
    console.error('Error fetching existing samplings:', error)
    toast.error('Gagal memuat data sampling yang ada')
  }
}


// Initialize
onMounted(async () => {
  console.log('Component mounted, initializing data...')
  
  try {
    // Reset pagination first
    employeePagination.value = {
      currentPage: 1,
      hasMore: true,
      loading: false
    }
    
    // Initial loads
    await Promise.all([
      fetchEmployees(),
      fetchData(),
      fetchSOPs()
    ])
    
    console.log('Initial data loaded:', {
      employeesCount: availableEmployees.value.length,
      ordersCount: orders.value.length,
      sopsCount: availableSOPs.value.length
    })
  } catch (error) {
    console.error('Error during initialization:', error)
    toast.error('Terjadi kesalahan saat memuat data awal')
  }
})

// Watchers
watch(selectedRole, () => {
  fetchData({ page: 1 })
  fetchSOPs()
})


// 3. Hapus watch yang me-reset employeeSelections, ganti dengan yang lebih smart
watch(selectedSOPs, (newSOPs, oldSOPs) => {
  // Hanya set search queries dan filters untuk SOP baru
  const addedSOPs = newSOPs.filter(id => !oldSOPs.includes(id))
  addedSOPs.forEach(sopId => {
    if (!employeeSearchQueries.value[sopId]) {
      employeeSearchQueries.value[sopId] = ''
    }
    if (!employeeDepartmentFilters.value[sopId]) {
      employeeDepartmentFilters.value[sopId] = ''
    }
  })

  // Hapus data untuk SOP yang di-uncheck
  const removedSOPs = oldSOPs.filter(id => !newSOPs.includes(id))
  removedSOPs.forEach(sopId => {
    delete employeeSearchQueries.value[sopId]
    delete employeeDepartmentFilters.value[sopId]
    delete employeeSelections.value[sopId]
  })
}, { deep: true })

// 4. Tambah method handleSOPSelection untuk handle checkbox events
// Perbaiki juga handleSOPSelection
const handleSOPSelection = (event, sop) => {
  const isChecked = event.target.checked
  console.log('SOP Selection changed:', { 
    sopId: sop.id, 
    isChecked, 
    currentSelections: employeeSelections.value 
  })
  
  // Jika unchecked, hapus employee selection untuk SOP ini
  if (!isChecked) {
    delete employeeSelections.value[sop.id]
  } 
  // Jika checked, kita tidak perlu melakukan apa-apa karena
  // employeeSelections seharusnya sudah ter-preserve
}
</script>

<style scoped>
.dialog-content {
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
}
</style>