<!-- src/views/front-office/DailyCheck.vue -->
<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-3 sm:p-6"> <!-- Padding lebih kecil di mobile -->
    <div class="bg-white rounded-lg shadow-sm border p-3 sm:p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
          <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">Equipment Check</h1>
          <button
            @click="openCreateDialog"
            class="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            Buat Check Baru
          </button>
        </div>

        <!-- Filter Section -->
        <div class="flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-4">
          <!-- Search - Full width di mobile -->
          <div class="relative w-full sm:w-80">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Cari check list..."
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            >
            <div class="absolute left-3 top-2.5">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
            </div>
          </div>

          <!-- Filters - Stack di mobile -->
          <div class="flex flex-col sm:flex-row gap-2">
            <input
              type="month"
              v-model="selectedMonth"
              class="w-full sm:w-auto border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @change="handleFilterChange"
            >
            <select
              v-model="selectedState"
              class="w-full sm:w-auto border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @change="handleFilterChange"
            >
              <option value="">Semua Status</option>
              <option value="draft">Draft</option>
              <option value="done">Selesai</option>
            </select>
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto -mx-3 sm:mx-0 rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="py-3 px-2 sm:px-4 text-left font-medium text-gray-700">
                  <span class="hidden sm:inline">Tanggal</span>
                  <span class="sm:hidden">Info</span>
                </th>
                <th class="py-3 px-2 sm:px-4 text-left font-medium text-gray-700 hidden sm:table-cell">Valet</th>
                <th class="py-3 px-2 sm:px-4 text-center font-medium text-gray-700 hidden sm:table-cell">Kelengkapan</th>
                <th class="py-3 px-2 sm:px-4 text-center font-medium text-gray-700">Status</th>
                <th class="py-3 px-2 sm:px-4 text-center font-medium text-gray-700 w-24 sm:w-32">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="check in rows" :key="check.id" class="border-t hover:bg-gray-50">
                <td class="py-3 px-2 sm:px-4">
                  <div class="sm:hidden">
                    <p class="font-medium">{{ formatDate(check.date) }}</p>
                    <p class="text-sm text-gray-500 mt-1">{{ check.valet?.name }}</p>
                    <p class="text-sm text-gray-500 mt-1">
                      {{ check.metrics.complete_items }}/{{ check.metrics.total_items }}
                      ({{ Math.round(check.metrics.completeness_rate) }}%)
                    </p>
                  </div>
                  <span class="hidden sm:inline">{{ formatDate(check.date) }}</span>
                </td>
                <td class="py-3 px-2 sm:px-4 hidden sm:table-cell">{{ check.valet?.name }}</td>
                <td class="py-3 px-2 sm:px-4 text-center hidden sm:table-cell">
                  {{ check.metrics.complete_items }}/{{ check.metrics.total_items }}
                  ({{ Math.round(check.metrics.completeness_rate) }}%)
                </td>
                <td class="py-3 px-2 sm:px-4 text-center">
                  <span 
                    class="inline-flex px-2 py-1 text-xs font-medium rounded-full"
                    :class="check.state === 'done' ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'"
                  >
                    {{ check.state === 'done' ? 'Selesai' : 'Draft' }}
                  </span>
                </td>
                <td class="py-3 px-2 sm:px-4">
                  <div class="flex justify-center space-x-1 sm:space-x-2">
                    <button
                      @click="openDetailDialog(check)"
                      class="p-1 text-blue-600 hover:bg-blue-50 rounded"
                      title="Detail"
                    >
                      <EyeIcon class="w-5 h-5" />
                    </button>
                    <button
                      v-if="check.state === 'draft'"
                      @click="openEditDialog(check)"
                      class="p-1 text-blue-600 hover:bg-blue-50 rounded"
                      title="Edit"
                    >
                      <PencilIcon class="w-5 h-5" />
                    </button>
                    <button
                      v-if="check.state === 'draft'"
                      @click="openCompleteDialog(check)" 
                      class="p-1 text-green-600 hover:bg-green-50 rounded"
                      title="Selesai"
                    >
                      <CheckIcon class="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Ganti bagian pagination yang ada dengan yang ini -->
        <div 
          v-if="pagination && rows.length > 0" 
          class="flex flex-col md:flex-row justify-between items-center gap-4"
          >
          <div class="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
            <span class="text-sm text-gray-600 text-center sm:text-left">
              Menampilkan {{ startNumber }} sampai {{ endNumber }} 
              dari {{ pagination.total_items }} data
            </span>
            
            <select
                v-model="itemsPerPage"
                @change="handlePerPageChange"
                class="border rounded-lg px-2 py-1 text-sm w-full sm:w-auto"
              >
              <option :value="10">10 per halaman</option>
              <option :value="20">20 per halaman</option>
              <option :value="50">50 per halaman</option>
              <option :value="100">100 per halaman</option>
            </select>
          </div>

          <div class="flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-end">
            <button
              @click="handlePageChange(pagination.current_page - 1)"
              :disabled="!pagination.has_previous"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Sebelumnya
            </button>
            
            <!-- Tambahkan nomor halaman -->
            <div class="flex gap-1">
              <template v-for="page in pageNumbers" :key="page">
                <span v-if="page === '...'" class="px-3 py-1">
                  ...
                </span>
                <button
                  v-else
                  @click="handlePageChange(page)"
                  class="px-3 py-1 rounded-lg border"
                  :class="pagination.current_page === page 
                    ? 'bg-blue-50 border-blue-500 text-blue-600' 
                    : 'hover:bg-gray-50'"
                >
                  {{ page }}
                </button>
              </template>
            </div>
            
            <button 
              @click="handlePageChange(pagination.current_page + 1)"
              :disabled="!pagination.has_next"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Dialog -->
    <TransitionRoot appear :show="showDialog" as="template">
      <Dialog as="div" @close="closeDialog" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-lg sm:max-w-2xl transform rounded-2xl bg-white p-4 sm:p-6 text-left shadow-xl transition-all mx-3 sm:mx-0">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              {{ isEditing ? 'Edit Check' : 'Check Baru' }}
            </DialogTitle>

             <!-- Progress Bar ditambahkan di sini -->
            <div class="mb-4">
              <div class="flex justify-between text-sm mb-1">
                <span>Progress Kelengkapan</span>
                <span>{{ calculateProgress }}%</span>
              </div>
              <div class="bg-gray-100 rounded-full h-2.5">
                <div 
                  class="bg-blue-500 h-2.5 rounded-full transition-all"
                  :style="`width: ${calculateProgress}%`"
                ></div>
              </div>
            </div>

            <form @submit.prevent="handleSubmit" class="space-y-4">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">Tanggal</label>
                  <input 
                    type="date"
                    v-model="formData.date"
                    required
                    :disabled="isEditing"
                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">Valet</label>
                  <select
                    v-model="formData.valet_id"
                    required
                    :disabled="isEditing"
                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="" disabled>Pilih Valet</option>
                    <option v-for="valet in valets" :key="valet.id" :value="valet.id">
                      {{ valet.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Equipment Checklist -->
              <div v-if="formData.check_lines?.length > 0" class="border rounded-lg p-4">
                <div class="flex justify-between items-center mb-4">
                  <h4 class="font-medium text-gray-900">Daftar Peralatan</h4>
                  <div class="flex space-x-2">
                    <button
                      type="button"
                      @click="toggleAllChecklines(true)"
                      class="px-3 py-1.5 text-sm bg-green-50 text-green-600 rounded hover:bg-green-100"
                    >
                      <CheckIcon class="w-4 h-4 inline-block mr-1" />
                      Centang Semua
                    </button>
                    <button
                      type="button"
                      @click="toggleAllChecklines(false)"
                      class="px-3 py-1.5 text-sm bg-gray-50 text-gray-600 rounded hover:bg-gray-100"
                    >
                      <XMarkIcon class="w-4 h-4 inline-block mr-1" />
                      Kosongkan Semua
                    </button>
                  </div>
                </div>
                <h4 class="font-medium text-gray-900 mb-4">Daftar Peralatan</h4>
                <div class="space-y-4">
                  <!-- Ganti bagian status di equipment checklist -->
                  <div v-for="line in formData.check_lines" :key="line.id" class="flex items-center justify-between">
                    <div>
                      <p class="font-medium">{{ line.equipment.name }}</p>
                      <input
                        type="text"
                        v-model="line.notes"
                        class="mt-1 w-full px-3 py-1 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Catatan (opsional)"
                      >
                    </div>
                    <div>
                      <label class="inline-flex items-center relative group">
                        <input
                          type="checkbox"
                          v-model="line.is_complete"
                          class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        >
                        <span 
                          class="ml-2 px-2 py-1 text-xs font-medium rounded-full"
                          :class="line.is_complete ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'"
                        >
                          {{ line.is_complete ? 'Lengkap & Rapi' : 'Tidak Lengkap' }}
                        </span>
                        <!-- Tooltip -->
                        <div class="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 whitespace-nowrap">
                          Terakhir diubah: {{ formatDate(line.write_date) }}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Catatan</label>
                <textarea
                  v-model="formData.notes"
                  rows="3"
                  class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Catatan tambahan (opsional)"
                ></textarea>
              </div>

              <div class="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  @click="closeDialog"
                  class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Batal
                </button>
                <button
                  type="submit"
                  class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  :disabled="loading"
                >
                  {{ isEditing ? 'Simpan' : 'Buat' }}
                </button>
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Detail Dialog -->
    <TransitionRoot appear :show="showDetailDialog" as="template">
      <Dialog as="div" @close="closeDetailDialog" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              Detail Check
            </DialogTitle>

            <div v-if="selectedItem" class="space-y-4">
              <!-- Info Header -->
              <div class="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                <div>
                  <p class="text-sm text-gray-500">Tanggal</p>
                  <p class="font-medium">{{ formatDate(selectedItem.date) }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-500">Valet</p>
                  <p class="font-medium">{{ selectedItem.valet?.name }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-500">Status</p>
                  <p>
                    <span 
                      class="inline-flex px-2 py-1 text-xs font-medium rounded-full"
                      :class="selectedItem.state === 'done' ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'"
                    >
                      {{ selectedItem.state === 'done' ? 'Selesai' : 'Draft' }}
                    </span>
                  </p>
                </div>
                <div>
                  <p class="text-sm text-gray-500">Kelengkapan</p>
                  <p class="font-medium">
                    {{ selectedItem.metrics.complete_items }}/{{ selectedItem.metrics.total_items }}
                    ({{ Math.round(selectedItem.metrics.completeness_rate) }}%)
                  </p>
                </div>
              </div>

              <!-- Equipment List -->
              <div class="border rounded-lg">
                <div class="px-4 py-3 bg-gray-50 border-b">
                  <h4 class="font-medium">Daftar Peralatan</h4>
                </div>
                <div class="divide-y">
                  <div 
                    v-for="line in selectedItem.check_lines" 
                    :key="line.id"
                    class="p-4 flex items-center justify-between"
                  >
                    <div>
                      <p class="font-medium">{{ line.equipment.name }}</p>
                      <p v-if="line.notes" class="text-sm text-gray-500 mt-1">
                        {{ line.notes }}
                      </p>
                    </div>
                    <div>
                      <span 
                        class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full"
                        :class="line.is_complete ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'"
                      >
                        {{ line.is_complete ? 'Lengkap & Rapi' : 'Tidak Lengkap' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Notes -->
              <div v-if="selectedItem.notes" class="p-4 bg-gray-50 rounded-lg">
                <p class="text-sm text-gray-500 mb-1">Catatan:</p>
                <p>{{ selectedItem.notes }}</p>
              </div>

              <!-- Actions -->
              <div class="mt-6 flex justify-end space-x-3">
                <button
                  v-if="selectedItem.state === 'draft'"
                  @click="confirmDelete"
                  class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                >
                  Hapus
                </button>
                <button
                  type="button"
                  @click="closeDetailDialog"
                  class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Tutup
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Complete Preview Dialog -->
    <TransitionRoot appear :show="showCompleteDialog" as="template">
      <Dialog as="div" @close="closeCompleteDialog" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              Konfirmasi Selesai Check
            </DialogTitle>

            <div v-if="selectedItem" class="space-y-4">
              <!-- Info Header -->
              <div class="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                <div>
                  <p class="text-sm text-gray-500">Tanggal</p>
                  <p class="font-medium">{{ formatDate(selectedItem.date) }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-500">Valet</p>
                  <p class="font-medium">{{ selectedItem.valet?.name }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-500">Kelengkapan</p>
                  <p class="font-medium">
                    {{ selectedItem.metrics.complete_items }}/{{ selectedItem.metrics.total_items }}
                    ({{ Math.round(selectedItem.metrics.completeness_rate) }}%)
                  </p>
                </div>
              </div>

              <!-- Equipment List -->
              <div class="border rounded-lg">
                <div class="px-4 py-3 bg-gray-50 border-b">
                  <h4 class="font-medium">Daftar Peralatan</h4>
                </div>
                <div class="divide-y">
                  <div 
                    v-for="line in selectedItem.check_lines" 
                    :key="line.id"
                    class="p-4 flex items-center justify-between"
                  >
                    <div>
                      <p class="font-medium">{{ line.equipment.name }}</p>
                      <p v-if="line.notes" class="text-sm text-gray-500 mt-1">
                        {{ line.notes }}
                      </p>
                    </div>
                    <div>
                      <span 
                        class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full"
                        :class="line.is_complete ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'"
                      >
                        {{ line.is_complete ? 'Lengkap & Rapi' : 'Tidak Lengkap' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Warning jika ada yang tidak lengkap -->
              <div v-if="selectedItem.metrics.complete_items < selectedItem.metrics.total_items" 
                class="p-4 bg-yellow-50 border border-yellow-200 rounded-lg"
              >
                <p class="text-yellow-700">
                  <ExclamationTriangleIcon class="w-5 h-5 inline-block mr-2" />
                  Beberapa peralatan belum lengkap. Pastikan semua sudah diperiksa dengan benar.
                </p>
              </div>

              <!-- Notes -->
              <div v-if="selectedItem.notes" class="p-4 bg-gray-50 rounded-lg">
                <p class="text-sm text-gray-500 mb-1">Catatan:</p>
                <p>{{ selectedItem.notes }}</p>
              </div>

              <!-- Actions -->
              <div class="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  @click="closeCompleteDialog"
                  class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Batal
                </button>
                <button
                  @click="handleComplete"
                  class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  :disabled="loading"
                >
                  <span v-if="loading">Memproses...</span>
                  <span v-else>Selesaikan Check</span>
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Delete Confirmation Dialog -->
    <TransitionRoot appear :show="showDeleteDialog" as="template">
      <Dialog as="div" @close="closeDeleteDialog" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              Konfirmasi Hapus
            </DialogTitle>

            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Apakah Anda yakin ingin menghapus check ini? Tindakan ini tidak dapat dibatalkan.
              </p>
            </div>

            <div class="mt-6 flex justify-end space-x-3">
              <button
                @click="closeDeleteDialog"
                class="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                Batal
              </button>
              <button
                @click="handleDelete"
                class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                :disabled="loading"
              >
                Hapus
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { 
  MagnifyingGlassIcon,
  PencilIcon,
  EyeIcon,
  CheckIcon,
  ExclamationTriangleIcon 
} from '@heroicons/vue/24/outline'
import { Toaster, toast } from 'vue-sonner'
// import { format } from 'date-fns'
import debounce from 'lodash/debounce'
import apiClient from '../../config/api'

// 2. Basic state yang tidak bergantung pada helper
const loading = ref(false)
const rows = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const selectedState = ref('')
const showDialog = ref(false)
const showDetailDialog = ref(false)
const isEditing = ref(false)
const selectedItem = ref(null)
const itemsPerPage = ref(20)
const valets = ref([])
const showDeleteDialog = ref(false)
// 1. Tambah state
const showCompleteDialog = ref(false)

// 1. Helper functions
const formatDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('id-ID', {
    year: 'numeric',
    month: '2-digit', 
    day: '2-digit'
  })
}

const getTodayDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const getCurrentMonth = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  return `${year}-${month}`
}

// 3. State yang bergantung pada helper function
const selectedMonth = ref('')
const formData = ref({
  date: '',
  valet_id: '',
  notes: '',
  check_lines: []
})

// 4. Fungsi inisialisasi state
const initState = () => {
  selectedMonth.value = getCurrentMonth()
  formData.value = {
    date: getTodayDate(),
    valet_id: '',
    notes: '',
    check_lines: []
  }
}

const toggleAllChecklines = (value) => {
  formData.value.check_lines = formData.value.check_lines.map(line => ({
    ...line,
    is_complete: value
  }))
}

const calculateProgress = computed(() => {
  if (!formData.value.check_lines?.length) return 0
  const completed = formData.value.check_lines.filter(line => line.is_complete).length
  return Math.round((completed / formData.value.check_lines.length) * 100)
})

// Methods untuk batch actions
const selectedItems = ref([])

const toggleItemSelection = (line) => {
  const index = selectedItems.value.findIndex(item => item.id === line.id)
  if (index > -1) {
    selectedItems.value.splice(index, 1)
  } else {
    selectedItems.value.push(line)
  }
}

const batchSetStatus = (status) => {
  selectedItems.value.forEach(item => {
    const line = formData.value.check_lines.find(l => l.id === item.id)
    if (line) {
      line.is_complete = status
    }
  })
  selectedItems.value = []
}

// Helper untuk format class status
const getStatusClass = (isComplete) => {
  return isComplete 
    ? 'bg-green-100 text-green-700' 
    : 'bg-red-100 text-red-700'
}

const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const payload = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        month: selectedMonth.value,
        state: selectedState.value || null
      }
    }

    const response = await apiClient.post('/web/front-office/check/list', payload)
    
    if (response?.data?.result?.status === 'success') {
      const { rows: checkRows, pagination: paginationData } = response.data.result.data
      rows.value = checkRows
      pagination.value = paginationData
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch data')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}


const handleDelete = async () => {
  if (!selectedItem.value) return

  try {
    loading.value = true
    const response = await apiClient.post(`/web/front-office/check/${selectedItem.value.id}/delete`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      toast.success('Check berhasil dihapus')
      closeDeleteDialog()
      
      // Refresh data
      const currentPage = pagination.value.current_page
      const shouldGoToPrevPage = rows.value.length === 1 && currentPage > 1
      fetchData({ 
        page: shouldGoToPrevPage ? currentPage - 1 : currentPage 
      })
    } else {
      throw new Error(response?.data?.result?.message || 'Operation failed')
    }
  } catch (error) {
    console.error('Error deleting check:', error)
    toast.error('Gagal menghapus check: ' + error.message)
  } finally {
    loading.value = false
  }
}

const fetchValets = async () => {
  try {
    const response = await apiClient.post('/web/v2/hr/employees', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        department_id: null  // Akan difilter di backend untuk Valet only
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      // valets.value = response.data.result.data
      // show valets only use job_title is valet parking
      valets.value = response.data.result.data.filter(valet => valet.job_title === 'Valet Parking')
    }
  } catch (error) {
    console.error('Error fetching valets:', error)
    toast.error('Gagal memuat data valet')
  }
}

const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = () => {
  fetchData({ page: 1 })
}

const handlePageChange = (page) => {
  fetchData({ page })
}

const resetForm = () => {
  formData.value = {
    date: getTodayDate(),
    valet_id: '',
    notes: '',
    check_lines: [],
    id: null // Tambahkan id untuk handling edit
  }
  isEditing.value = false
  selectedItem.value = null
}

const openCreateDialog = async () => {
  // Reset form terlebih dahulu
  resetForm()
  
  try {
    // Load daftar equipment aktif
    loading.value = true
    const response = await apiClient.post('/web/front-office/equipment/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        limit: 100, // Ambil semua equipment aktif
        show_inactive: false
      }
    })

    if (response?.data?.result?.status === 'success') {
      // Transform equipment list menjadi format check_lines
      formData.value.check_lines = response.data.result.data.rows.map(equipment => ({
        equipment: {
          id: equipment.id,
          name: equipment.name
        },
        is_complete: false,
        notes: ''
      }))
      
      // Buka dialog setelah data siap
      showDialog.value = true
    } else {
      throw new Error(response?.data?.result?.message || 'Gagal memuat data peralatan')
    }
  } catch (error) {
    console.error('Error:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

const validateForm = () => {
  const errors = []
  
  // Required fields
  if (!formData.value.date) errors.push('Tanggal wajib diisi')
  if (!formData.value.valet_id) errors.push('Valet wajib dipilih')
  
  // Validate check lines
  if (!formData.value.check_lines?.length) {
    errors.push('Tidak ada daftar peralatan yang aktif')
  }
  
  // Additional validation untuk edit mode
  if (isEditing.value && !formData.value.id) {
    errors.push('ID check tidak valid')
  }

  if (errors.length > 0) {
    errors.forEach(error => toast.error(error))
    return false
  }
  return true
}

const openDetailDialog = async (item) => {
  try {
    loading.value = true
    const response = await apiClient.post(`/web/front-office/check/${item.id}/detail`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      selectedItem.value = response.data.result.data
      showDetailDialog.value = true
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to get check detail')
    }
  } catch (error) {
    console.error('Error getting check detail:', error)
    toast.error('Gagal memuat detail check: ' + error.message)
  } finally {
    loading.value = false
  }
}

const closeDialog = () => {
  showDialog.value = false
  resetForm()
}

const closeDetailDialog = () => {
  showDetailDialog.value = false
  selectedItem.value = null
}

const handleSubmit = async () => {
  if (!validateForm()) return
  
  try {
    loading.value = true
    
    if (isEditing.value) {
      // Update existing check
      const response = await apiClient.post(`/web/front-office/check/${selectedItem.value.id}/update`, {
        jsonrpc: "2.0",
        method: "call",
        params: {
          notes: formData.value.notes,
          check_lines: formData.value.check_lines.map(line => ({
            id: line.id,
            is_complete: line.is_complete,
            notes: line.notes || ''
          }))
        }
      })

      if (response?.data?.result?.status === 'success') {
        toast.success('Check berhasil diperbarui')
        closeDialog()
        fetchData({ page: pagination.value?.current_page || 1 })
      }

    } else {
      // Create new check
      const createResponse = await apiClient.post('/web/front-office/check/create', {
        jsonrpc: "2.0",
        method: "call",
        params: {
          valet_id: parseInt(formData.value.valet_id),
          date: formData.value.date,
          notes: formData.value.notes || ''
        }
      })

      if (createResponse?.data?.result?.status === 'success') {
        // Tunggu 500ms untuk memastikan data tersimpan
        await new Promise(resolve => setTimeout(resolve, 500))
        
        // Get detail untuk mendapatkan ID check_lines
        const detailResponse = await apiClient.post(`/web/front-office/check/${createResponse.data.result.data.id}/detail`, {
          jsonrpc: "2.0",
          method: "call"
        })

        if (detailResponse?.data?.result?.status === 'success') {
          // Update dengan check_lines yang sudah ada ID nya
          const updateResponse = await apiClient.post(`/web/front-office/check/${createResponse.data.result.data.id}/update`, {
            jsonrpc: "2.0",
            method: "call",
            params: {
              notes: formData.value.notes,
              check_lines: detailResponse.data.result.data.check_lines.map((line, index) => ({
                id: line.id,
                is_complete: formData.value.check_lines[index].is_complete,
                notes: formData.value.check_lines[index].notes || ''
              }))
            }
          })

          if (updateResponse?.data?.result?.status === 'success') {
            toast.success('Check berhasil dibuat')
            closeDialog()
            fetchData({ page: 1 })
          }
        }
      }
    }
  } catch (error) {
    console.error('Error:', error)
    toast.error('Gagal menyimpan data: ' + error.message)
  } finally {
    loading.value = false
  }
}

const openEditDialog = async (item) => {
  try {
    loading.value = true
    const response = await apiClient.post(`/web/front-office/check/${item.id}/detail`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      const checkData = response.data.result.data
      
      formData.value = {
        id: checkData.id,
        date: checkData.date,
        valet_id: checkData.valet.id,
        notes: checkData.notes || '',
        check_lines: checkData.check_lines.map(line => ({
          id: line.id,
          is_complete: line.is_complete,
          notes: line.notes || '',
          equipment: line.equipment // Tetap simpan info equipment untuk display
        }))
      }
      
      selectedItem.value = item
      isEditing.value = true
      showDialog.value = true
    }
  } catch (error) {
    console.error('Error:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

// 2. Tambah fungsi untuk membuka modal complete
const openCompleteDialog = async (item) => {
  try {
    loading.value = true
    // Get detail data dulu
    const response = await apiClient.post(`/web/front-office/check/${item.id}/detail`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      selectedItem.value = response.data.result.data
      showCompleteDialog.value = true
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to get check detail')
    }
  } catch (error) {
    console.error('Error:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

// 3. Modifikasi fungsi complete
const handleComplete = async () => {
  try {
    loading.value = true
    const response = await apiClient.post(`/web/front-office/check/${selectedItem.value.id}/complete`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      toast.success('Check berhasil diselesaikan')
      closeCompleteDialog()
      fetchData({ page: pagination.value?.current_page || 1 })
    } else {
      throw new Error(response?.data?.result?.message)
    }
  } catch (error) {
    console.error('Error:', error)
    toast.error('Gagal menyelesaikan check: ' + error.message)
  } finally {
    loading.value = false
  }
}

const closeCompleteDialog = () => {
  showCompleteDialog.value = false
  selectedItem.value = null
}

// Computed
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const totalPages = Math.ceil(pagination.value.total_items / itemsPerPage.value)
  const current = pagination.value.current_page
  const delta = 2 // Jumlah halaman yang ditampilkan sebelum dan sesudah halaman saat ini
  const pages = []
  
  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 || // Halaman pertama
      i === totalPages || // Halaman terakhir
      (i >= current - delta && i <= current + delta) // Halaman sekitar current
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

const handlePerPageChange = () => {
  fetchData({ 
    page: 1,
    limit: itemsPerPage.value 
  })
}


const confirmDelete = (item) => {
  selectedItem.value = item
  showDeleteDialog.value = true
}

const closeDeleteDialog = () => {
  showDeleteDialog.value = false
  selectedItem.value = null
}


// Lifecycle hooks
onMounted(() => {
  initState()
  fetchData()
  fetchValets()
})
</script>