<!-- src/views/front-office/EquipmentMaster.vue -->
<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center mb-6">
          <h1 class="text-2xl font-semibold text-gray-900">Master Peralatan Front Office</h1>
          <button
            @click="openCreateDialog"
            class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            Tambah Peralatan
          </button>
        </div>

        <!-- Search Section -->
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
          <div class="relative w-full md:w-80">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Cari peralatan..."
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            >
            <div class="absolute left-3 top-2.5">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
            </div>
          </div>

          <!-- Filter Active/Inactive -->
          <div class="flex space-x-2">
            <select
              v-model="showInactive"
              class="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @change="handleFilterChange"
            >
              <option :value="false">Aktif</option>
              <option :value="true">Semua Status</option>
            </select>
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Nama</th>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Deskripsi</th>
                <th class="py-3 px-4 text-center font-medium text-gray-700 w-32">Status</th>
                <th class="py-3 px-4 text-center font-medium text-gray-700 w-32">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="item in rows" 
                :key="item.id"
                class="border-t hover:bg-gray-50"
              >
                <td class="py-3 px-4">{{ item.name }}</td>
                <td class="py-3 px-4">{{ item.description }}</td>
                <td class="py-3 px-4 text-center">
                  <span 
                    class="px-2 py-1 text-xs font-medium rounded-full"
                    :class="item.active ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'"
                  >
                    {{ item.active ? 'Aktif' : 'Non-aktif' }}
                  </span>
                </td>
                <td class="py-3 px-4">
                  <div class="flex justify-center space-x-2">
                    <button
                      @click="openEditDialog(item)"
                      class="p-1 text-blue-600 hover:bg-blue-50 rounded"
                    >
                      <PencilIcon class="w-5 h-5" />
                    </button>
                    <button
                      @click="confirmDelete(item)"
                      class="p-1 text-red-600 hover:bg-red-50 rounded"
                    >
                      <TrashIcon class="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Ganti bagian pagination yang ada dengan yang ini -->
        <div 
          v-if="pagination && rows.length > 0" 
          class="flex flex-col md:flex-row justify-between items-center gap-4"
          >
          <div class="flex items-center gap-4">
            <span class="text-sm text-gray-600">
              Menampilkan {{ startNumber }} sampai {{ endNumber }} 
              dari {{ pagination.total_items }} data
            </span>
            
            <select
              v-model="itemsPerPage"
              @change="handlePerPageChange"
              class="border rounded-lg px-2 py-1 text-sm"
            >
              <option :value="10">10 per halaman</option>
              <option :value="20">20 per halaman</option>
              <option :value="50">50 per halaman</option>
              <option :value="100">100 per halaman</option>
            </select>
          </div>

          <div class="flex items-center gap-2">
            <button
              @click="handlePageChange(pagination.current_page - 1)"
              :disabled="!pagination.has_previous"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Sebelumnya
            </button>
            
            <!-- Tambahkan nomor halaman -->
            <div class="flex gap-1">
              <template v-for="page in pageNumbers" :key="page">
                <span v-if="page === '...'" class="px-3 py-1">
                  ...
                </span>
                <button
                  v-else
                  @click="handlePageChange(page)"
                  class="px-3 py-1 rounded-lg border"
                  :class="pagination.current_page === page 
                    ? 'bg-blue-50 border-blue-500 text-blue-600' 
                    : 'hover:bg-gray-50'"
                >
                  {{ page }}
                </button>
              </template>
            </div>
            
            <button 
              @click="handlePageChange(pagination.current_page + 1)"
              :disabled="!pagination.has_next"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Dialog -->
    <TransitionRoot appear :show="showDialog" as="template">
      <Dialog as="div" @close="closeDialog" class="relative z-50">
        <!-- ... dialog overlay ... -->
        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              {{ isEditing ? 'Edit Peralatan' : 'Tambah Peralatan Baru' }}
            </DialogTitle>

            <form @submit.prevent="handleSubmit" class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Nama Peralatan</label>
                <input 
                  type="text"
                  v-model="formData.name"
                  required
                  class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Masukkan nama peralatan"
                >
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Deskripsi</label>
                <textarea
                  v-model="formData.description"
                  rows="3"
                  class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Masukkan deskripsi peralatan"
                ></textarea>
              </div>

              <div class="flex items-center">
                <input
                  type="checkbox"
                  v-model="formData.active"
                  class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                >
                <label class="ml-2 text-sm text-gray-700">Aktif</label>
              </div>

              <div class="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  @click="closeDialog"
                  class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Batal
                </button>
                <button
                  type="submit"
                  class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  :disabled="loading"
                >
                  {{ isEditing ? 'Simpan' : 'Tambah' }}
                </button>
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Delete Confirmation Dialog -->
    <TransitionRoot appear :show="showDeleteDialog" as="template">
      <Dialog as="div" @close="closeDeleteDialog" class="relative z-50">
        <!-- ... dialog overlay ... -->
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
            <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
              Konfirmasi Hapus
            </DialogTitle>

            <p class="text-sm text-gray-700 mb-4">
              Apakah Anda yakin ingin menghapus peralatan <strong>{{ selectedItem?.name }}</strong>?
            </p>

            <div class="flex justify-end space-x-3">
              <button
                type="button"
                @click="closeDeleteDialog"
                class="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                Batal
              </button>
              <button
                type="button"
                @click="handleDelete"
                class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                :disabled="loading"
              >
                Hapus
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot } from '@headlessui/vue'
import { 
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/vue/24/outline'
import { Toaster, toast } from 'vue-sonner'
import apiClient from '../../config/api'
import debounce from 'lodash/debounce'

// State
const loading = ref(false)
const rows = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const showInactive = ref(false)
const showDialog = ref(false)
const showDeleteDialog = ref(false)
const isEditing = ref(false)
const selectedItem = ref(null)
const itemsPerPage = ref(20)

const formData = ref({
  name: '',
  description: '',
  active: true
})

// Computed
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const totalPages = Math.ceil(pagination.value.total_items / itemsPerPage.value)
  const current = pagination.value.current_page
  const delta = 2 // Jumlah halaman yang ditampilkan sebelum dan sesudah halaman saat ini
  const pages = []
  
  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 || // Halaman pertama
      i === totalPages || // Halaman terakhir
      (i >= current - delta && i <= current + delta) // Halaman sekitar current
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

// Methods
const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = () => {
  fetchData({ page: 1 })
}

const handlePageChange = (page) => {
  fetchData({ page })
}

const handlePerPageChange = () => {
  fetchData({ 
    page: 1,
    limit: itemsPerPage.value 
  })
}

const resetForm = () => {
  formData.value = {
    name: '',
    description: '',
    active: true
  }
  isEditing.value = false
  selectedItem.value = null
}

const openCreateDialog = () => {
  resetForm()
  showDialog.value = true
}

const openEditDialog = (item) => {
  selectedItem.value = item
  formData.value = { ...item }
  isEditing.value = true
  showDialog.value = true
}

const closeDialog = () => {
  showDialog.value = false
  resetForm()
}

const confirmDelete = (item) => {
  selectedItem.value = item
  showDeleteDialog.value = true
}

const closeDeleteDialog = () => {
  showDeleteDialog.value = false
  selectedItem.value = null
}

const handleSubmit = async () => {
  try {
    loading.value = true
    let response;

    if (isEditing.value) {
      // Update existing equipment
      response = await apiClient.post(`/web/front-office/equipment/${selectedItem.value.id}/update`, {
        jsonrpc: "2.0",
        method: "call",
        params: {
          name: formData.value.name,
          description: formData.value.description,
          active: formData.value.active
        }
      });
    } else {
      // Create new equipment
      response = await apiClient.post('/web/front-office/equipment/create', {
        jsonrpc: "2.0",
        method: "call",
        params: {
          name: formData.value.name,
          description: formData.value.description,
          active: formData.value.active
        }
      });
    }

    if (response?.data?.result?.status === 'success') {
      toast.success(
        isEditing.value ? 
          'Peralatan berhasil diperbarui' : 
          'Peralatan berhasil ditambahkan'
      )
      closeDialog()
      fetchData({ page: pagination.value?.current_page || 1 })
    } else {
      throw new Error(response?.data?.result?.message || 'Operation failed')
    }
  } catch (error) {
    console.error('Error submitting form:', error)
    toast.error('Gagal menyimpan data: ' + error.message)
  } finally {
    loading.value = false
  }
}

const handleDelete = async () => {
  if (!selectedItem.value) return

  try {
    loading.value = true
    const response = await apiClient.post(
      `/web/front-office/equipment/${selectedItem.value.id}/delete`, 
      {
        jsonrpc: "2.0",
        method: "call"
      }
    );

    if (response?.data?.result?.status === 'success') {
      toast.success(response.data.result.message || 'Peralatan berhasil dihapus')
      closeDeleteDialog()
      
      // Refresh current page, or go to previous page if current page is empty
      const currentPage = pagination.value.current_page
      const shouldGoToPrevPage = rows.value.length === 1 && currentPage > 1
      fetchData({ 
        page: shouldGoToPrevPage ? currentPage - 1 : currentPage 
      })
    } else {
      throw new Error(response?.data?.result?.message || 'Operation failed')
    }
  } catch (error) {
    console.error('Error deleting item:', error)
    toast.error('Gagal menghapus data: ' + error.message)
  } finally {
    loading.value = false
  }
}

// Contoh fetchData:
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const payload = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        show_inactive: showInactive.value
      }
    }

    const response = await apiClient.post('/web/front-office/equipment/list', payload)
    
    if (response?.data?.result?.status === 'success') {
      const { rows: equipmentRows, pagination: paginationData } = response.data.result.data
      rows.value = equipmentRows
      pagination.value = paginationData
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch data')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchData()
})
</script>